import * as types from './types'
import { ordersApi } from '../../api'
import { apiRequest } from './api-request'
import { getAuthToken, checkHttpError } from './authTokenHelper'
import { showNotification } from './notifications'
import { NOTIFICATION_TYPES } from '../../constants/notification-types'

export const resetModifyOrderStatus = () => ({
  type: types.RESET_MODIFY_ORDER
})

export const addOrder = ({ order }, successCallback) => (dispatch, getState) => {
  dispatch(resetModifyOrderStatus())
  const apiReqAction = apiRequest({ requestType: types.ADD_ORDER_REQ })
  dispatch(apiReqAction)

  ordersApi
    .addOrder(getAuthToken(getState()), { order })
    .then(
      orderId => {
        dispatch({
          type: types.ADD_ORDER_OK,
          order: {
            id: orderId,
            ...order
          },
          apiReqId: apiReqAction.id
        })
        dispatch(
          showNotification({
            message: `Order added (P.O: ${order.purchaseOrder})`,
            options: {
              variant: NOTIFICATION_TYPES.Success
            }
          })
        )
        if (successCallback && typeof successCallback === 'function') successCallback()
      },
      error => {
        if (error.message === '409') { // Purchase Order number conflict
          dispatch({
            type: types.MODIFY_ORDER_FAIL,
            failMessage: `Purchase Order "${order.purchaseOrder}" already exists.`
          })
          dispatch(
            showNotification({
              message: 'Failed to add order',
              options: {
                variant: NOTIFICATION_TYPES.Error
              }
            })
          )
          dispatch({ type: types.API_END, apiReqId: apiReqAction.id })
        } else {
          throw new Error(error.message || 'Add order failed')
        }
      }
    )
    .catch(error => {
      dispatch(
        checkHttpError({
          error,
          apiReqAction,
          genericErrorMsg: 'Add order failed',
          onSuccessCallback: () => {
            dispatch(addOrder({ order }))
            dispatch({ type: types.API_END, apiReqId: apiReqAction.id })
          }
        }))
    })
}

export const updateOrder = ({ orderId, order }, successCallback) => (dispatch, getState) => {
  dispatch(resetModifyOrderStatus())
  const apiReqAction = apiRequest({ requestType: types.UPDATE_ORDER_REQ })
  dispatch(apiReqAction)

  ordersApi
    .updateOrder(getAuthToken(getState()), { orderId, order })
    .then(
      result => {
        if (!result) throw new Error('Unable to update order')
        dispatch({
          type: types.UPDATE_ORDER_OK,
          order: {
            id: orderId,
            ...order
          },
          apiReqId: apiReqAction.id
        })
        dispatch(
          showNotification({
            message: `Order updated (P.O: ${order.purchaseOrder})`,
            options: {
              variant: NOTIFICATION_TYPES.Info
            }
          })
        )
        if (successCallback && typeof successCallback === 'function') successCallback()
      },
      error => {
        if (error.message === '409') { // Purchase Order number conflict
          dispatch({
            type: types.MODIFY_ORDER_FAIL,
            failMessage: `Purchase Order "${order.purchaseOrder}" already exists.`
          })
          dispatch(
            showNotification({
              message: 'Failed to update order',
              options: {
                variant: NOTIFICATION_TYPES.Error
              }
            })
          )
          dispatch({ type: types.API_END, apiReqId: apiReqAction.id })
        } else {
          throw new Error(error.message || 'Update order failed')
        }
      }
    )
    .catch(error => {
      dispatch(
        checkHttpError({
          error,
          apiReqAction,
          genericErrorMsg: 'Update order failed',
          onSuccessCallback: () => {
            dispatch(addOrder({ order }))
            dispatch({ type: types.API_END, apiReqId: apiReqAction.id })
          }
        }))
    })
}

export const getOrders = () => (dispatch, getState) => {
  const apiReqAction = apiRequest({ requestType: types.ORDERS_REQ })
  dispatch(apiReqAction)

  ordersApi
    .getOrders(getAuthToken(getState()))
    .then(
      orders => {
        dispatch({
          type: types.GET_ORDERS_OK,
          orders: orders.map(o => ({
            ...o,
            isPriority: o.isPriority === 1,
            isRecurring: o.isRecurring === 1,
            isAccountValid: o.isAccountValid === 1,
            isCash: o.isCash === 1,
            deliveryDateTime: o.deliveryDateTime || '',
            deliveryDocket: o.deliveryDocket || '',
            deliveryInstructions: o.deliveryInstructions || '',
            deliveryTeam: o.deliveryTeam || ''
          })),
          apiReqId: apiReqAction.id
        })
      },
      error => {
        throw new Error(error.message || 'Get orders failed')
      }
    )
    .catch(error => {
      dispatch(
        checkHttpError({
          error,
          apiReqAction,
          genericErrorMsg: 'Get orders failed',
          onSuccessCallback: () => {
            dispatch(getOrders())
            dispatch({ type: types.API_END, apiReqId: apiReqAction.id })
          }
        }))
    })
}

export const getLastDeliveryTimes = () => (dispatch, getState) => {
  const apiReqAction = apiRequest({ requestType: types.LAST_DELIVERIES_REQ })
  dispatch(apiReqAction)

  ordersApi
    .getLastDeliveryTimes(getAuthToken(getState()))
    .then(
      lastDeliveries => {
        dispatch({
          type: types.GET_LAST_DELIVERIES_OK,
          lastDeliveries,
          apiReqId: apiReqAction.id
        })
      },
      error => {
        throw new Error(error.message || 'Get last deliveries failed')
      }
    )
    .catch(error => {
      dispatch(
        checkHttpError({
          error,
          apiReqAction,
          genericErrorMsg: 'Get last deliveries failed',
          onSuccessCallback: () => {
            dispatch(getLastDeliveryTimes())
            dispatch({ type: types.API_END, apiReqId: apiReqAction.id })
          }
        }))
    })
}
