import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

function Modal ({
  open,
  onClose,
  title,
  children,
  buttons,
  dialogTitleStyle,
  dialogProps,
  progress
}) {
  return (
    <Dialog {...dialogProps} open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title' style={dialogTitleStyle}>
        {title}
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' width='100%'>
          {progress && (
            <Box width='50%' display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center'>
              {progress}
            </Box>
          )}
          <Box width={progress ? '50%' : '100%'} display='flex' flexDirection='row' justifyContent='flex-end' alignItems='center'>
            {buttons.map(({ id, ...buttonProps }, i) => <Button key={id || i} {...buttonProps} />)}
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

Modal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  buttons: PropTypes.arrayOf(PropTypes.shape()),
  dialogProps: PropTypes.shape()
}

Modal.defaultProps = {
  buttons: [],
  dialogProps: {}
}

export default Modal
