import { createSelector } from 'reselect'

const dialogSelector = state => state.dialogs

export const makeSelectIsAddUserDialogOpen = () =>
  createSelector(
    [dialogSelector],
    dialogs => dialogs.addUser
  )

export const makeSelectIsOrderDialogOpen = () =>
  createSelector(
    [dialogSelector],
    dialogs => dialogs.order
  )

export const makeSelectIsProductDialogOpen = () =>
  createSelector(
    [dialogSelector],
    dialogs => dialogs.product
  )

export const makeSelectIsCustomerDialogOpen = () =>
  createSelector(
    [dialogSelector],
    dialogs => dialogs.customer
  )

export const makeSelectIsExportOrderCSVDialogOpen = () =>
  createSelector(
    [dialogSelector],
    dialogs => dialogs.ordersCSVExport
  )
