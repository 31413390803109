import requestSetup from '../httpRequest'

const {
  loginRequest: _loginRequest,
  authRequest: _authRequest,
  nonAuthRequest: _nonAuthRequest
} = requestSetup()

export const loginRequest = _loginRequest
export const authRequest = _authRequest
export const nonAuthRequest = _nonAuthRequest
