import { v4 as uuidv4 } from 'uuid'
import * as types from './types'

export const showNotification = notification => {
  const key = notification.options && notification.options.key

  return {
    type: types.ENQUEUE_NOTIFICATION,
    notification: {
      ...notification,
      key: key || uuidv4()
    }
  }
}

// This action can close all notifications
export const closeNotification = key => ({
  type: types.CLOSE_NOTIFICATION,
  dismissAll: !key, // dismiss all if no key has been defined
  key
})

// This action can only close one notification
export const removeNotification = key => ({
  type: types.REMOVE_NOTIFICATION,
  key
})
