import { createSelector } from 'reselect'

const ordersSelector = state => state.orders
const orderModifiedStatusSelector = state => state.orders.orderModified

export const makeSelectOrders = () =>
  createSelector(
    [ordersSelector],
    orders => orders.list
  )

export const makeSelectedOrder = () =>
  createSelector(
    [ordersSelector],
    orders => {
      if (!orders.selectedOrderId) return null
      const order = orders.list.find(o => o.id === orders.selectedOrderId)
      return order || null
    }
  )

export const makeSelectOrderAddedStatus = () =>
  createSelector(
    [orderModifiedStatusSelector],
    status => status
  )

export const makeSelectLastDeliveries = () =>
  createSelector(
    [ordersSelector],
    orders => orders.lastDeliveries
  )
