
// API
export const API_REQUEST = 'API_REQUEST'
export const API_END = 'API_END'

// Customers
export const GET_CUSTOMERS_OK = 'GET_CUSTOMERS_OK'

// Dialogs
export const DIALOG_ADD_USER = 'DIALOG_ADD_USER'
export const DIALOG_ORDER = 'DIALOG_ORDER'
export const DIALOG_PRODUCT = 'DIALOG_PRODUCT'
export const DIALOG_CUSTOMER = 'DIALOG_CUSTOMER'
export const DIALOG_EXPORT_ORDERS_CSV = 'DIALOG_EXPORT_ORDERS_CSV'

// Login/Logout
export const LOGIN_OK = 'LOGIN_OK'
export const LOGOUT = 'LOGOUT'

// Export Orders (CSV)
export const GET_CSV_EXPORT_OK = 'GET_CSV_EXPORT_OK'
export const RESET_CSV_EXPORT = 'RESET_CSV_EXPORT'

// Orders
export const GET_ORDERS_OK = 'GET_ORDERS_OK'
export const ADD_ORDER_OK = 'ADD_ORDER_OK'
export const MODIFY_ORDER_FAIL = 'MODIFY_ORDER_FAIL'
export const RESET_MODIFY_ORDER = 'RESET_MODIFY_ORDER'
export const UPDATE_ORDER_OK = 'UPDATE_ORDER_OK'
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL'
export const GET_LAST_DELIVERIES_OK = 'GET_LAST_DELIVERIES_OK'

// Products
export const GET_PRODUCTS_OK = 'GET_PRODUCTS_OK'

// Refresh Token
export const REFRESH_TOKEN_OK = 'REFRESH_TOKEN_OK'

// Toast Notifications
export const ENQUEUE_NOTIFICATION = 'ENQUEUE_NOTIFICATION'
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'

// Users
export const GET_USERS_OK = 'GET_USERS_OK'
export const ADD_USER_OK = 'ADD_USER_OK'
export const DELETE_USER = 'DELETE_USER'
export const TOGGLE_USER_STATUS = 'TOGGLE_USER_STATUS'
export const CHANGE_PASSWORD_OK = 'CHANGE_PASSWORD_OK'

// API Request Types
/* Authentication */
export const API_LOGIN_REQ = 'API_LOGIN_REQ'
export const API_CHANGE_PASSWORD_REQ = 'API_CHANGE_PASSWORD_REQ'
/* Customers */
export const CUSTOMERS_REQ = 'CUSTOMERS_REQ'
/* Export Orders */
export const EXPORT_ORDERS_REQ = 'EXPORT_ORDERS_REQ'
/* Orders */
export const ADD_ORDER_REQ = 'ADD_ORDER_REQ'
export const UPDATE_ORDER_REQ = 'UPDATE_ORDER_REQ'
export const ORDERS_REQ = 'ORDERS_REQ'
export const LAST_DELIVERIES_REQ = 'LAST_DELIVERIES_REQ'
/* Products */
export const PRODUCTS_REQ = 'PRODUCTS_REQ'
/* Users */
export const USERS_REQ = 'USERS_REQ'
export const USERS_ADD_REQ = 'USERS_ADD_REQ'
export const USERS_DEL_REQ = 'USERS_DEL_REQ'
export const USERS_STATUS_REQ = 'USERS_STATUS_REQ'
