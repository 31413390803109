import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import UserDialog from '../components/UserDialog'
import { toggleAddUserDialog } from '../lib/store/actions/dialogs'
import { addUser } from '../lib/store/actions/users'
import { makeSelectIsAddUserDialogOpen } from '../lib/store/selectors/dialogs'

function UserAddDialog ({ isOpen, onToggleDialog, onAddUser }) {
  const handleSubmit = (values) => {
    const { name, email, role, password } = values
    onAddUser({ name, email, role, password })
  }
  return <UserDialog isOpen={isOpen} onClose={onToggleDialog} onSubmit={handleSubmit} />
}

UserAddDialog.propTypes = {
  isOpen: PropTypes.bool,
  onToggleDialog: PropTypes.func,
  onAddUser: PropTypes.func
}

const mapStateToProps = state => {
  const selectIsAddUserDialogOpen = makeSelectIsAddUserDialogOpen()
  return {
    isOpen: selectIsAddUserDialogOpen(state)
  }
}

export default connect(
  mapStateToProps,
  {
    onToggleDialog: toggleAddUserDialog,
    onAddUser: addUser
  }
)(UserAddDialog)
