import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Protected from './Protected'
import withReduxPersistor from './withReduxPersistor'
import { logout } from '../lib/store/actions/auth'

const Logout = ({ onLogout, persistor }) => {
  React.useEffect(() => {
    onLogout(persistor)
  }, [onLogout, persistor])

  return null
}

export default compose(
  Protected(role => role === 'admin' || role === 'user'),
  withReduxPersistor,
  connect(
    null,
    {
      onLogout: logout
    }
  )
)(Logout)
