import React from 'react'
import isPropsEqual from 'react-fast-compare'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MUICheckbox from '@material-ui/core/Checkbox'

function Checkbox ({ label, ...checkboxProps }) {
  return (
    <FormControlLabel
      control={<MUICheckbox {...checkboxProps} />}
      label={label}
    />
  )
}

export default React.memo(Checkbox, isPropsEqual)
