import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Redirect, withRouter } from 'react-router-dom'
import { logout } from '../lib/store/actions/auth'
import { showNotification } from '../lib/store/actions/notifications'
import { APP_ROUTES } from '../lib/constants/app-routes'
import { makeSelectIsAuth } from '../lib/store/selectors/auth'
import { makeSelectUserRole } from '../lib/store/selectors/user'

const ProtectComponent = (reqRoleFunc) => Component => {
  class Protected extends React.PureComponent {
    render () {
      const { isAuth, userRole, location, ...passThroughProps } = this.props

      // Check if authorised
      if (!isAuth) {
        const newLocation = {
          pathname: APP_ROUTES.login,
          state: location.pathname !== APP_ROUTES.login && location.pathname !== APP_ROUTES.logout
            ? { from: { pathname: location.pathname } }
            : undefined
        }
        return <Redirect to={newLocation} />
      }

      if (!reqRoleFunc(userRole)) {
        return <Redirect to={APP_ROUTES.notfound} />
      }

      return <Component {...passThroughProps} />
    }
  }

  Protected.propTypes = {
    isAuth: PropTypes.bool,
    userRole: PropTypes.string
  }

  const mapStateToProps = state => {
    const selectIsAuth = makeSelectIsAuth()
    const selectUserRole = makeSelectUserRole()
    return {
      isAuth: selectIsAuth(state),
      userRole: selectUserRole(state)
    }
  }

  return compose(
    connect(
      mapStateToProps,
      {
        onLogout: logout,
        onShowNotification: showNotification
      }
    ),
    withRouter
  )(Protected)
}

export function secureComponent (Component) {
  return ProtectComponent()(Component)
}

export default ProtectComponent
