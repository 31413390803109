import React from 'react'
import { compose } from 'recompose'
import DashboardIcon from '@material-ui/icons/Dashboard'
import { makeStyles } from '@material-ui/core/styles'
import Protected from '../containers/Protected'
import PageTemplate from '../components/PageTemplate'
import Orders from '../containers/Orders'
import OrderDialog from '../containers/OrderDialog'
import OrderExportDialog from '../containers/OrderExportDialog'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1)
  }
}))

const OrdersPage = () => {
  const classes = useStyles()

  return (
    <PageTemplate
      title={
        <>
          <DashboardIcon className={classes.icon} /> Customer Orders
        </>
      }
      gridContainerProps={{
        spacing: 3,
        direction: 'row',
        justify: 'center',
        alignItems: 'center'
      }}
      gridItemProps={{
        xs: 12,
        lg: 12
      }}
    >
      <OrderExportDialog />
      <OrderDialog />
      <Orders />
    </PageTemplate>
  )
}

export default compose(
  Protected((role) => role === 'admin' || role === 'user')
)(OrdersPage)
