import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import Badge from '@material-ui/core/Badge'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    color: '#efefef'
  },
  selected: {
    color: '#222',
    background: 'linear-gradient(45deg, #6bbefe 30%, #0065b3 90%)',
    borderRadius: 3,
    border: 0
  },
  link: {
    color: '#efefef'
  },
  linkSelected: {
    color: '#222'
  }
})

const DashboardNavMenuItem = ({ icon: Icon, text, linkTo, isSelected, notificationCount }) => {
  const classes = useStyles()
  const renderLink = React.forwardRef((itemProps, ref) => (
    <RouterLink to={linkTo} {...itemProps} innerRef={ref} />
  ))
  renderLink.displayName = 'MenuRouterLink'

  const iconComponent = notificationCount ? (
    <Badge badgeContent={notificationCount} color='error'>
      <Icon />
    </Badge>
  ) : (
    <Icon />
  )

  return (
    <li>
      <ListItem
        button
        component={renderLink}
        selected={isSelected}
        classes={{
          root: classes.root,
          selected: classes.selected
        }}
      >
        <ListItemIcon className={isSelected ? classes.linkSelected : classes.link}>
          {iconComponent}
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    </li>
  )
}

DashboardNavMenuItem.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.string,
  linkTo: PropTypes.string,
  isSelected: PropTypes.bool,
  notificationCount: PropTypes.number
}

export default DashboardNavMenuItem
