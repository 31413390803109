import { createReducer } from 'redux-create-reducer'
import * as types from '../actions/types'

const initialState = {
  selectedProductId: null,
  list: []
}

export const products = createReducer(initialState, {
  [types.GET_PRODUCTS_OK] (state, action) {
    const { products: list } = action
    return {
      ...state,
      list: [...list]
    }
  },
  [types.DIALOG_PRODUCT] (state, action) {
    const { productId } = action
    return {
      ...state,
      selectedProductId: state.selectedProductId ? null : productId
    }
  },
  [types.LOGOUT] () {
    return initialState
  }
})
