import React from 'react'
import isPropsEqual from 'react-fast-compare'
import ButtonComponent from '@material-ui/core/Button'
import MUIIconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

const IconButtonComp = function ({ onClick, label, icon, color, disabled }) {
  return (
    <Tooltip title={label} placement='top'>
      <div>
        <MUIIconButton
          aria-label={label}
          onClick={onClick}
          color={color}
          disabled={disabled}
        >
          {icon}
        </MUIIconButton>
      </div>
    </Tooltip>
  )
}

export const IconButton = React.memo(IconButtonComp, isPropsEqual)

function Button (props) {
  return <ButtonComponent {...props} />
}

export default React.memo(Button, isPropsEqual)
