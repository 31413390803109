import { createSelector } from 'reselect'

const userSelector = state => state.user.user

export const makeSelectUserRole = () =>
  createSelector(
    [userSelector],
    user => user.role
  )

export const makeSelectUser = () =>
  createSelector(
    [userSelector],
    user => user
  )

export const makeSelectUserName = () =>
  createSelector(
    [userSelector],
    user => user.displayName
  )
