import React from 'react'
import PropTypes from 'prop-types'
import isPropsEqual from 'react-fast-compare'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DashboardNavMenu from '../components/DashboardNavMenu'
import AppDrawer from '../components/AppDrawer'
import { NAVIGATION_MENU_ITEMS } from '../lib/constants/navigation-menu'
import { APP_ROUTES } from '../lib/constants/app-routes'
import { makeSelectUserRole } from '../lib/store/selectors/user'

const DashboardNavigation = ({ userRole, isDrawerOpen, location: { pathname }, notificationCounts, version }) => {
  const menuItemKeys = Object.keys(NAVIGATION_MENU_ITEMS)
  const menu = menuItemKeys.map((key, menuIndex) => {
    const { heading, menuItems } = NAVIGATION_MENU_ITEMS[key]

    const filteredMenuItems = menuItems.filter(m => {
      if (m.restrictToRole) return m.restrictToRole === userRole
      return true
    })

    return (
      <DashboardNavMenu
        key={menuIndex}
        heading={heading}
        menuItems={filteredMenuItems}
        hasDivider={menuIndex < menuItemKeys.length - 1}
        currentPath={pathname}
        notificationCounts={notificationCounts}
      />
    )
  })

  return <AppDrawer isDrawerOpen={isDrawerOpen} menu={menu} appVersion={version} />
}

DashboardNavigation.propTypes = {
  userRole: PropTypes.string,
  isDrawerOpen: PropTypes.bool,
  location: PropTypes.shape({ pathname: PropTypes.string }),
  notificationCounts: PropTypes.shape({ [APP_ROUTES.dashboard]: PropTypes.number }),
  version: PropTypes.string
}

const mapStateToProps = state => {
  const selectUserRole = makeSelectUserRole()
  const unreadCount = 0
  return {
    userRole: selectUserRole(state),
    notificationCounts: {
      [APP_ROUTES.dashboard]: unreadCount
    }
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps)
)(React.memo(DashboardNavigation, isPropsEqual))
