import * as types from './types'

export const toggleAddUserDialog = () => ({
  type: types.DIALOG_ADD_USER
})

export const toggleOrderDialog = (orderId) => ({
  type: types.DIALOG_ORDER,
  orderId
})

export const toggleProductDialog = (productId) => ({
  type: types.DIALOG_PRODUCT,
  productId
})

export const toggleCustomerDialog = (customerId) => ({
  type: types.DIALOG_CUSTOMER,
  customerId
})

export const toggleExportOrdersCSVDialog = () => ({
  type: types.DIALOG_EXPORT_ORDERS_CSV
})
