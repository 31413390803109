import { createReducer } from 'redux-create-reducer'
import * as types from '../actions/types'

const initialState = {
  auth: {},
  user: {}
}

export const user = createReducer(initialState, {
  [types.LOGIN_OK] (state, action) {
    const { auth, user } = action

    return {
      ...state,
      auth: { ...auth },
      user: { ...user }
    }
  },
  [types.REFRESH_TOKEN_OK] (state, action) {
    const { idToken } = action

    return {
      ...state,
      auth: {
        ...state.auth,
        idToken
      }
    }
  },
  [types.LOGOUT] () {
    return initialState
  }
})
