import { createSelector } from 'reselect'

const ordersSelector = state => state.exportOrders

export const selectExportOrders =
  createSelector(
    [ordersSelector],
    orders => orders.list
  )

export const selectHasExportOrders =
  createSelector(
    [ordersSelector],
    orders => orders.ordersFound
  )
