import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import FaceIcon from '@material-ui/icons/Face'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

const useLineStyles = makeStyles(() => ({
  title: {
    color: '#8a8a8a',
    fontSize: '0.9em'
  }
}))

function Line ({ title, value, tooltip }) {
  const classes = useLineStyles()

  return (
    <Box display='flex' flexDirection='column'>
      <div><span className={classes.title}>{title}</span></div>
      <div>
        <span style={{ fontWeight: 'bold' }}>{value}</span> {tooltip && <span>({tooltip})</span>}
      </div>
    </Box>
  )
}

Line.propTypes = {
  tooltip: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string
}

function UserProfile ({
  name,
  username,
  role,
  onSubmitChangePassword,
  isLoading
}) {
  const initialValues = {
    currentPassword: '',
    newPassword: '',
    repeatPassword: '',
    isSubmitted: false
  }

  const [values, setValues] = React.useState(initialValues)

  const handleChange = field => event => {
    const { value } = event.target
    setValues(vals => ({ ...vals, [field]: value }))
  }

  const handleSubmit = () => {
    setValues(vals => ({ ...vals, isSubmitted: true }))
    const { currentPassword, newPassword, repeatPassword } = values
    if (String(currentPassword).length === 0) return
    if (String(newPassword).length === 0) return
    if (String(repeatPassword).length === 0) return
    if (repeatPassword !== newPassword) return

    onSubmitChangePassword({ currentPassword, newPassword, repeatPassword })
  }

  const buttonProps = {
    onClick: handleSubmit,
    color: 'primary',
    children: 'Change Password',
    variant: 'outlined',
    disabled: isLoading
  }

  const defaultTextFieldProps = {
    fullWidth: true,
    variant: 'outlined'
  }

  const fields = [
    {
      ...defaultTextFieldProps,
      id: 'currentPassword',
      label: 'Current Password',
      type: 'password',
      required: true,
      value: values.currentPassword,
      onChange: handleChange('currentPassword'),
      error: values.isSubmitted && String(values.currentPassword).length === 0
    },
    {
      ...defaultTextFieldProps,
      id: 'newPassword',
      label: 'New Password',
      type: 'password',
      required: true,
      value: values.newPassword,
      onChange: handleChange('newPassword'),
      error: values.isSubmitted && String(values.newPassword).length === 0
    },
    {
      ...defaultTextFieldProps,
      id: 'repeatPassword',
      label: 'Repeat New Password',
      type: 'password',
      required: true,
      value: values.password,
      onChange: handleChange('repeatPassword'),
      error: values.isSubmitted && (String(values.repeatPassword).length === 0 || values.newPassword !== values.repeatPassword)
    }
  ]

  return (
    <Box my={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={1}>
          <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
            <FaceIcon color='primary' style={{ fontSize: 100 }} />
          </Box>
        </Grid>
        <Grid item md={11}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <Line title='Name' value={name} />
            </Grid>
            <Grid item xs={12}>
              <Line title='Login Email' value={username} />
            </Grid>
            <Grid item xs={12}>
              <Line title='Role' value={role} tooltip={role === 'admin' ? 'User can create/update Orders, edit Order data and User setup' : 'User can create/update orders'} />
            </Grid>
            <Grid item xs={12}>
              <Box pt={2} pb={1} display='flex' flexDirection='column' style={{ borderTop: '1px solid #efefef' }}>
                <Typography component='h2' variant='body1' style={{ fontWeight: 'bold' }}>
                  Change Your Password
                </Typography>
              </Box>
              <form id='password-change-form'>
                <Grid container spacing={2}>
                  {fields.map(({ id, ...props }) => (
                    <Grid key={id} item xs={12} md={4}>
                      <TextField id={id} {...props} />
                    </Grid>
                  ))}
                  <Grid item xs={12} md={4}>
                    <Button {...buttonProps} />
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

UserProfile.propTypes = {
  name: PropTypes.string,
  username: PropTypes.string,
  role: PropTypes.string,
  onSubmitChangePassword: PropTypes.func,
  isLoading: PropTypes.bool
}

export default UserProfile
