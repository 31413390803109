import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { changePassword } from '../lib/store/actions/auth'
import { makeSelectUser } from '../lib/store/selectors/user'
import { selectIsLoadingChangePassword } from '../lib/store/selectors/auth'
import UserProfile from '../components/UserProfile'

function Profile ({ user, isLoading, onChangePassword }) {
  const handleChangePassword = ({ currentPassword, newPassword }) => {
    onChangePassword({ currentPassword, newPassword })
  }

  return (
    <UserProfile
      name={user.displayName}
      username={user.email}
      role={user.role}
      onSubmitChangePassword={handleChangePassword}
      isLoading={isLoading}
    />
  )
}

Profile.propTypes = {
  user: PropTypes.shape({
    displayName: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string
  }),
  isLoading: PropTypes.bool,
  onChangePassword: PropTypes.func
}

const mapStateToProps = state => {
  const selectUser = makeSelectUser()
  return {
    user: selectUser(state),
    isLoading: selectIsLoadingChangePassword(state)
  }
}

export default connect(
  mapStateToProps,
  {
    onChangePassword: changePassword
  }
)(Profile)
