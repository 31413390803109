import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import { PERSIST, PURGE, REHYDRATE } from 'redux-persist/es/constants'
import { createStateSyncMiddleware } from 'redux-state-sync'
import storage from 'redux-persist/lib/storage'
import rootReducer from './reducers'

const persistConfig = {
  key: 'purewater-dashboard',
  storage,
  throttle: 2000
  // whitelist: ['user']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const stateSyncConfig = {
  blacklist: [PERSIST, PURGE, REHYDRATE]
}

const configureStore = () => {
  const middleware = [thunk, createStateSyncMiddleware(stateSyncConfig)]

  if (process.env.NODE_ENV !== 'production') {
    middleware.push(createLogger())
  }

  const store = createStore(persistedReducer, applyMiddleware(...middleware))
  const persistor = persistStore(store)
  return { store, persistor }
}

export default configureStore
