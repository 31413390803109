import { combineReducers } from 'redux'
import { withReduxStateSync } from 'redux-state-sync'
import { apiRequest } from './api-request'
import { customers } from './customers'
import { dialogs } from './dialogs'
import { exportOrders } from './exportOrders'
import { notifications } from './notifications'
import { orders } from './orders'
import { products } from './products'
import { user } from './user'
import { users } from './users'

const rootReducer = combineReducers({
  apiRequest,
  customers,
  dialogs,
  exportOrders,
  notifications,
  orders,
  products,
  user,
  users
})

export default withReduxStateSync(rootReducer)
