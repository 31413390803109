/*
 * NOTE: Icons are found here: https://material.io/tools/icons/?style=baseline
 * */

import DashboardIcon from '@material-ui/icons/Dashboard'
import PersonIcon from '@material-ui/icons/Person'
import PeopleIcon from '@material-ui/icons/People'
import LogoutIcon from '@material-ui/icons/ExitToApp'
// import ProductsIcon from '@material-ui/icons/ShoppingCart'
// import CustomersIcon from '@material-ui/icons/PeopleAlt'
// import CrewIcon from '@material-ui/icons/LocalShipping'
import { APP_ROUTES } from './app-routes'

export const NAVIGATION_MENU_ITEMS = {
  main: {
    heading: '',
    menuItems: [
      {
        icon: DashboardIcon,
        linkTo: APP_ROUTES.orders,
        text: 'Orders'
      }
    ]
  },
  // orderSettings: {
  //   heading: 'Order Settings',
  //   menuItems: [
  //     {
  //       icon: ProductsIcon,
  //       linkTo: APP_ROUTES.products,
  //       text: 'Products',
  //       restrictToRole: 'admin'
  //     },
  //     {
  //       icon: CustomersIcon,
  //       linkTo: APP_ROUTES.customers,
  //       text: 'Customers',
  //       restrictToRole: 'admin'
  //     },
  //     {
  //       icon: CrewIcon,
  //       linkTo: APP_ROUTES.deliverycrew,
  //       text: 'Delivery Crews',
  //       restrictToRole: 'admin'
  //     }
  //   ]
  // },
  appSettings: {
    heading: 'App Settings',
    menuItems: [
      {
        icon: PeopleIcon,
        linkTo: APP_ROUTES.usersetup,
        text: 'User Setup',
        restrictToRole: 'admin'
      },
      {
        icon: PersonIcon,
        linkTo: APP_ROUTES.profile,
        text: 'My Profile'
      }
    ]
  },
  other: {
    heading: '',
    menuItems: [
      // {
      //   icon: HelpIcon,
      //   linkTo: APP_ROUTES.placeholder,
      //   text: 'Help'
      // },
      {
        icon: LogoutIcon,
        linkTo: APP_ROUTES.logout,
        text: 'Logout'
      }
    ]
  }
}
