import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import LoginForm from '../components/forms/LoginForm'
import { login } from '../lib/store/actions/auth'
import { selectIsLoadingLogin, makeSelectIsAuth } from '../lib/store/selectors/auth'
import { APP_ROUTES } from '../lib/constants/app-routes'

const Login = ({ onLogin, isLoading, isAuth, location }) => {
  const handleSubmit = ({ email, password }) => {
    onLogin(email, password)
  }

  if (isAuth) {
    const { state } = location
    const route = state?.from?.pathname || APP_ROUTES.orders
    return <Redirect to={route} />
  }

  return <LoginForm onSubmit={handleSubmit} isLoading={isLoading} />
}

Login.propTypes = {
  onLogin: PropTypes.func,
  isLoading: PropTypes.bool,
  isAuth: PropTypes.bool
}

const mapStateToProps = state => {
  const selectIsAuth = makeSelectIsAuth()
  return {
    isLoading: selectIsLoadingLogin(state),
    isAuth: selectIsAuth(state)
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    {
      onLogin: login
    }
  ))(Login)
