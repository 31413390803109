import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import PeopleIcon from '@material-ui/icons/People'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core/styles'
import Protected from '../containers/Protected'
import PageTemplate from '../components/PageTemplate'
import UserSetup from '../containers/UserSetup'
import UserAddDialog from '../containers/UserAddDialog'
import { toggleAddUserDialog } from '../lib/store/actions/dialogs'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1)
  }
}))

const UserSetupPage = ({ onToggleAddUserDialog }) => {
  const classes = useStyles()

  const pageMenuItems = [
    {
      title: (
        <>
          <AddIcon className={classes.icon} /> Add New User ...
        </>
      ),
      onClick: onToggleAddUserDialog
    }]

  return (
    <PageTemplate
      title={
        <>
          <PeopleIcon className={classes.icon} /> User Setup
        </>
      }
      menuItems={pageMenuItems}
      gridContainerProps={{
        spacing: 3,
        direction: 'row',
        justify: 'center',
        alignItems: 'center'
      }}
      gridItemProps={{
        xs: 12,
        lg: 12
      }}
    >
      <UserAddDialog />
      <UserSetup />
    </PageTemplate>
  )
}

UserSetupPage.propTypes = {
  onToggleAddUserDialog: PropTypes.func
}

export default compose(
  Protected(role => role === 'admin'),
  connect(
    null,
    {
      onToggleAddUserDialog: toggleAddUserDialog
    }
  )
)(UserSetupPage)
