export const ORDER_STATUS = {
  red: 'danger',
  green: 'success',
  black: 'default'
}

export const ORDER_STATUS_COLORS = {
  danger: {
    color: '#721c24',
    backgroundColor: '#f8d7da',
    borderColor: '#f5c6cb'
  },
  success: {
    color: '#155724',
    backgroundColor: '#d4edda',
    borderColor: '#c3e6cb'
  },
  default: {
    color: '#383d41',
    backgroundColor: '#e2e3e5',
    borderColor: '#d6d8db'
  }
}
