import { createSelector } from 'reselect'
import { USERS_REQ, EXPORT_ORDERS_REQ, ORDERS_REQ, ADD_ORDER_REQ, UPDATE_ORDER_REQ } from '../../store/actions/types'

const isDoingAPIRequestSelector = state => {
  return state.apiRequest
}
export const makeSelectIsDoingAPIRequest = () => createSelector(
  [isDoingAPIRequestSelector],
  requests => requests.length > 0
)

/* Export Orders */
export const selectIsDoingGetExportOrdersAPIRequest = createSelector(
  [isDoingAPIRequestSelector],
  requests => !!requests.find(r => r.requestType === EXPORT_ORDERS_REQ)
)

/* Orders */
export const makeSelectIsDoingGetOrdersAPIRequest = () => createSelector(
  [isDoingAPIRequestSelector],
  requests => !!requests.find(r => r.requestType === ORDERS_REQ)
)

export const makeSelectIsDoingAddOrderAPIRequest = () => createSelector(
  [isDoingAPIRequestSelector],
  requests => !!requests.find(r => r.requestType === ADD_ORDER_REQ)
)

export const makeSelectIsDoingUpdateOrderAPIRequest = () => createSelector(
  [isDoingAPIRequestSelector],
  requests => !!requests.find(r => r.requestType === UPDATE_ORDER_REQ)
)

/* Users */
export const makeSelectIsDoingGetUsersAPIRequest = () => createSelector(
  [isDoingAPIRequestSelector],
  requests => !!requests.find(r => r.requestType === USERS_REQ)
)
