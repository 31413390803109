import React from 'react'
import clsx from 'clsx'
import moment from 'moment'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import blueGrey from '@material-ui/core/colors/blueGrey'
import { makeStyles } from '@material-ui/core/styles'

const useDeliveryCardStyles = makeStyles(() => ({
  card: {
    borderRadius: 4,
    paddingTop: 0,
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 5,
    marginRight: 5,
    color: blueGrey[700],
    borderColor: blueGrey[50],
    backgroundColor: blueGrey[50]
  },
  crew: {
    fontSize: '1em',
    display: 'inline-block',
    marginRight: 5,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap'
  },
  datetime: {
    fontSize: '1em',
    whiteSpace: 'nowrap'
  }
}))

const DeliveryCard = ({ deliveryTeam, lastDeliveryDateTime }) => {
  const classes = useDeliveryCardStyles()
  return (
    <Box className={clsx(classes.card)} mt={1}>
      <div>
        <Typography component='span' variant='body2' className={classes.crew}>
          Crew {deliveryTeam}
        </Typography>
      </div>
      <div>
        <Typography component='span' variant='body1' className={classes.datetime}>
          {lastDeliveryDateTime ? moment(lastDeliveryDateTime).format('DD/MM/YYYY [@] HH:mm') : 'No last delivery'}
        </Typography>
      </div>
    </Box>
  )
}

function DeliveryStats ({ crewDeliveries }) {
  if (crewDeliveries.length === 0) return null

  return (
    <div>
      <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
        <Typography component='h2' variant='body2'><strong>Last Delivery Date / Time</strong></Typography>
      </Box>

      <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' flexWrap='wrap'>
        {crewDeliveries.map(({ deliveryTeam, lastDeliveryDateTime }) => (
          <DeliveryCard key={deliveryTeam} deliveryTeam={deliveryTeam} lastDeliveryDateTime={lastDeliveryDateTime} />
        ))}
      </Box>
    </div>
  )
}

DeliveryStats.defaultProps = {
  crewDeliveries: []
}

export default DeliveryStats
