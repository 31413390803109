export const PRODUCTS = [
  {
    id: 1,
    name: '330ml'
  },
  {
    id: 2,
    name: '600ml'
  },
  {
    id: 3,
    name: '1.5L'
  },
  {
    id: 4,
    name: '10L'
  },
  {
    id: 5,
    name: '19L'
  },
  {
    id: 6,
    name: 'Cradle'
  },
  {
    id: 7,
    name: 'Ceramic'
  },
  {
    id: 8,
    name: 'Bench Top (Hot)'
  },
  {
    id: 9,
    name: 'Bench Top (Cold)'
  }
]
