import React from 'react'
import PersonIcon from '@material-ui/icons/Person'
import { makeStyles } from '@material-ui/core/styles'
import PageTemplate from '../components/PageTemplate'
import Protected from '../containers/Protected'
import Profile from '../containers/Profile'

const useStyles = makeStyles(theme => ({
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1)
  }
}))

const ProfilePage = () => {
  const classes = useStyles()

  return (
    <PageTemplate
      title={
        <>
          <PersonIcon className={classes.icon} /> User Profile
        </>
      }
      gridContainerProps={{
        spacing: 3,
        direction: 'row',
        justify: 'center',
        alignItems: 'center'
      }}
      gridItemProps={{
        xs: 12,
        lg: 12
      }}
    >
      <Profile />
    </PageTemplate>
  )
}
export default Protected(role => role === 'admin' || role === 'user')(ProfilePage)
