import React from 'react'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListSubheader from '@material-ui/core/ListSubheader'
import { makeStyles } from '@material-ui/core/styles'
import DashboardNavMenuItem from './DashboardNavMenuItem'

const useStyles = makeStyles(() => ({
  heading: {
    color: '#abd6f7'
  },
  divider: {
    backgroundColor: 'rgba(239, 239, 239, 0.3)'
  }
}))

const DashboardNavMenu = ({ heading, menuItems, hasDivider, currentPath, notificationCounts }) => {
  const classes = useStyles()
  return (
    <div>
      <List>
        {heading && (
          <ListSubheader inset className={classes.heading}>
            {heading}
          </ListSubheader>
        )}
        {menuItems.map((item, index) => {
          return (
            <DashboardNavMenuItem
              key={index}
              {...item}
              isSelected={item.linkTo === currentPath}
              notificationCount={notificationCounts[item.linkTo]}
            />
          )
        })}
      </List>
      {hasDivider && <Divider classes={{ root: classes.divider }} />}
    </div>
  )
}

DashboardNavMenu.propTypes = {
  heading: PropTypes.string,
  menuItems: PropTypes.array,
  hasDivider: PropTypes.bool,
  currentPath: PropTypes.string,
  notificationCounts: PropTypes.shape()
}

export default DashboardNavMenu
