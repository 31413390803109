import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/styles'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Tooltip from '@material-ui/core/Tooltip'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { withDashboardLayout } from '../containers/withDashboardLayout'

const styles = theme => ({
  gridListroot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    padding: theme.spacing(2)
  },
  gridList: {
    maxWidth: '80%'
  },
  gridListTileBarRoot: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)'
  },
  gridListTileBarTitle: {
    color: '#222222'
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  menuButton: {
    marginLeft: 'auto'
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

class PageTemplate extends React.PureComponent {
  _isMounted = false; // eslint-disable-line

  state = {
    menuAnchor: null
  };

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  updateState = (state, callback) => {
    if (this._isMounted) {
      this.setState(state, () => {
        if (callback && typeof callback === 'function') callback()
      })
    }
  };

  handleOpenMenu = event => {
    if (this._isMounted) this.updateState({ menuAnchor: event.currentTarget })
  };

  handleCloseMenu = () => {
    if (this._isMounted) this.updateState({ menuAnchor: null })
  };

  handleClickMenuItem = callback => () => {
    if (callback && typeof callback === 'function') callback()
    this.handleCloseMenu()
  };

  render () {
    const {
      classes,
      title,
      menuItems,
      children,
      gridContainerProps,
      gridItemProps,
      isMenuDisabled
    } = this.props
    const { menuAnchor } = this.state
    return (
      <Grid container {...gridContainerProps}>
        <Grid item xs={12} {...gridItemProps}>
          <Paper className={classes.paper}>
            <Grid container justify='space-between' spacing={3} direction='row'>
              <Grid item className={classes.centerContent}>
                <Typography component='h2' variant='h6' color='primary' gutterBottom>
                  {title}
                </Typography>
              </Grid>
              {menuItems && (
                <Grid item>
                  <Tooltip title='More Options' placement='top'>
                    <div>
                      <IconButton
                        disabled={isMenuDisabled}
                        aria-label='More'
                        aria-controls='more-options-menu'
                        aria-haspopup='true'
                        onClick={this.handleOpenMenu}
                      >
                        <MenuIcon fontSize='large' />
                      </IconButton>
                    </div>
                  </Tooltip>
                  <Menu
                    id='more-options-menu'
                    anchorEl={menuAnchor}
                    keepMounted
                    open={Boolean(menuAnchor)}
                    onClose={this.handleCloseMenu}
                  >
                    {menuItems.map((menu, i) => (
                      <MenuItem key={i} onClick={this.handleClickMenuItem(menu.onClick)}>
                        {menu.title}
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              )}
            </Grid>
            <Divider />
            {children}
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

PageTemplate.propTypes = {
  classes: PropTypes.shape(),
  title: PropTypes.node,
  menuItems: PropTypes.array,
  children: PropTypes.node,
  gridContainerProps: PropTypes.shape(),
  gridItemProps: PropTypes.shape(),
  isMenuDisabled: PropTypes.bool
}

export default compose(
  withDashboardLayout,
  withStyles(styles)
)(PageTemplate)
