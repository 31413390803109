import React from 'react'

import { connect } from 'react-redux'
import OrderTable from '../components/OrdersTable'
import { toggleOrderDialog, toggleExportOrdersCSVDialog } from '../lib/store/actions/dialogs'
import { makeSelectOrders } from '../lib/store/selectors/orders'
import { makeSelectIsDoingGetOrdersAPIRequest } from '../lib/store/selectors/api-request'
import { getOrders, getLastDeliveryTimes } from '../lib/store/actions/orders'

function Orders ({
  orders,
  fullscreen,
  isLoading,
  onGetOrders,
  onGetLastDeliveryTimes,
  onToggleOrderDialog,
  onToggleExportCSVDialog
}) {
  const handleClickOrder = orderId => {
    onToggleOrderDialog(orderId)
  }

  const handleClickPopOut = () => {
    const ORDERS_TABLE_URL = String(window.location.href).replace(/orders/, 'orderstable')
    window.open(ORDERS_TABLE_URL, 'ORDERS_TABLE')
  }

  const handleClickExport = () => {
    onToggleExportCSVDialog()
  }

  const handleClickNewOrder = () => {
    onToggleOrderDialog(null)
  }

  const handleRefresh = () => {
    onGetOrders()
    onGetLastDeliveryTimes()
  }

  /**
   *
   * Order Coloring
   * - orders from previous days are RED
   * - standing orders are GREEN
   * - normal orders not delivered are BLACK
   * - delivered orders are TICKED (in red)
   *
   * Order Sorting (sorted from top to bottom)
   * 1) RED orders
   * 2) GREEN orders
   * 3) Priority orders
   * 4) BLACK orders
   * 5) Delivered orders
   *
   * Priority Tick defaults
   * - all RED orders
   * - all GREEN orders
   *
   *  */

  return (
    <OrderTable
      customerOrders={orders}
      isLoading={isLoading}
      onClickOrder={handleClickOrder}
      onClickNewOrder={handleClickNewOrder}
      onClickPopOut={handleClickPopOut}
      onClickExport={handleClickExport}
      onClickRefresh={handleRefresh}
      fullscreen={fullscreen}
    />
  )
}

Orders.defaultProps = {
  fullscreen: false
}

const mapStateToProps = state => {
  const selectOrders = makeSelectOrders()
  const selectIsLoading = makeSelectIsDoingGetOrdersAPIRequest()
  return {
    orders: selectOrders(state),
    isLoading: selectIsLoading(state)
  }
}

export default connect(
  mapStateToProps,
  {
    onToggleOrderDialog: toggleOrderDialog,
    onToggleExportCSVDialog: toggleExportOrdersCSVDialog,
    onGetOrders: getOrders,
    onGetLastDeliveryTimes: getLastDeliveryTimes
  }
)(Orders)
