/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import isPropsEqual from 'react-fast-compare'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Switch from '../components/forms/Switch'
import Checkbox from '../components/forms/Checkbox'
import DateTimePicker from '../components/forms/DateTimePicker'
import Modal from './Modal'
import LoadingIndicator from './LoadingIndicator'
import 'moment/min/locales'

moment.locale('en-AU')

function OrderDialog ({
  selectedOrder,
  isOpen,
  isLoading,
  orderModifiedStatus,
  onClose,
  onSubmit
}) {
  const initialValues = {
    customer: '',
    phone: '',
    isAccountValid: false,
    isCash: false,
    quantity: 0,
    deliveryInstructions: '',
    deliveryDateTime: null,
    deliveryDocket: '',
    deliveryTeam: '',
    isPriority: false,
    purchaseOrder: '',
    isRecurring: false,
    orderDateTime: new Date(),
    isSubmitted: false
  }

  const [values, setValues] = React.useState(initialValues)

  React.useEffect(() => {
    if (!isOpen) {
      setValues({
        customer: '',
        phone: '',
        isAccountValid: false,
        isCash: false,
        quantity: 0,
        deliveryInstructions: '',
        deliveryDateTime: null,
        deliveryDocket: '',
        deliveryTeam: '',
        isPriority: false,
        purchaseOrder: '',
        isRecurring: false,
        isSubmitted: false
      })
    }
  }, [isOpen, setValues])

  React.useEffect(() => {
    if (selectedOrder) {
      setValues({
        customer: selectedOrder.customer,
        phone: selectedOrder.customerPhone ?? '',
        isAccountValid: selectedOrder.isAccountValid,
        isCash: selectedOrder.isCash,
        quantity: selectedOrder.quantity,
        deliveryInstructions: selectedOrder.deliveryInstructions,
        deliveryDateTime: selectedOrder.deliveryDateTime ? moment(selectedOrder.deliveryDateTime).format('YYYY-MM-DD HH:mm:ss') : null,
        deliveryDocket: selectedOrder.deliveryDocket,
        deliveryTeam: selectedOrder.deliveryTeam,
        isPriority: selectedOrder.isPriority,
        purchaseOrder: selectedOrder.purchaseOrder,
        isRecurring: selectedOrder.isRecurring,
        orderDateTime: selectedOrder.orderDateTime
      })
    }
  }, [selectedOrder])

  const handleInputChange = field => event => {
    const { value } = event.target
    setValues(vals => ({ ...vals, [field]: value }))
  }

  const handleSwitchChange = field => event => {
    const { checked } = event.target

    if (field === 'isRecurring') {
      return setValues(vals => ({ ...vals, [field]: checked, isPriority: checked }))
    }

    setValues(vals => ({ ...vals, [field]: checked }))
  }

  const handleDateChange = field => date => {
    setValues(vals => ({
      ...vals,
      [field]: date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null
    }))
  }

  const handleClose = () => {
    setValues(initialValues)
    onClose()
  }

  const handleSubmit = () => {
    setValues(vals => ({ ...vals, isSubmitted: true }))

    const { isSubmitted, customer, phone, quantity, purchaseOrder, orderDateTime, ...restVals } = values // eslint-disable-line

    if (customer.length === 0) return
    if (purchaseOrder.length === 0) return

    onSubmit({
      customer,
      customerPhone: phone,
      purchaseOrder,
      orderDateTime: moment(orderDateTime).format('YYYY-MM-DD HH:mm:ss'),
      quantity: String(quantity) === '' ? 0 : quantity,
      ...restVals
    })
  }

  const buttons = [
    {
      id: '1',
      onClick: handleClose,
      color: 'primary',
      children: 'Cancel',
      variant: 'outlined',
      style: { marginRight: 10 },
      disabled: isLoading
    },
    {
      id: '2',
      onClick: handleSubmit,
      color: 'primary',
      children: 'Submit',
      variant: 'contained',
      disabled: isLoading
    }
  ]

  const defaultTextFieldProps = {
    fullWidth: true,
    variant: 'outlined',
    disabled: isLoading
  }

  const defaultCheckboxProps = {
    disabled: isLoading
  }

  const fieldsLeft = [
    {
      ...defaultTextFieldProps,
      autoFocus: true,
      id: 'customer',
      label: 'Customer Name',
      type: 'text',
      required: true,
      value: values.customer,
      onChange: handleInputChange('customer'),
      error: values.isSubmitted && String(values.customer).length === 0,
      grid: {
        xs: 12,
        lg: 8
      }
    },
    {
      ...defaultTextFieldProps,
      id: 'phone',
      label: 'Phone',
      type: 'text',
      value: values.phone,
      onChange: handleInputChange('phone'),
      grid: {
        xs: 12,
        lg: 4
      }
    },
    {
      ...defaultCheckboxProps,
      id: 'isAccountValid',
      label: 'Account Status Confirmed?',
      type: 'checkbox',
      color: 'primary',
      checked: values.isAccountValid,
      onChange: handleSwitchChange('isAccountValid'),
      grid: {
        xs: 12,
        lg: 8
      }
    },
    {
      ...defaultCheckboxProps,
      id: 'isCash',
      label: 'Cash',
      type: 'checkbox',
      color: 'primary',
      checked: values.isCash,
      onChange: handleSwitchChange('isCash'),
      grid: {
        xs: 12,
        lg: 4
      }
    },
    {
      ...defaultTextFieldProps,
      id: 'deliveryTeam',
      label: 'Crew',
      type: 'text',
      value: values.deliveryTeam,
      onChange: handleInputChange('deliveryTeam'),
      grid: {
        xs: 12,
        lg: 2
      }
    },
    {
      ...defaultTextFieldProps,
      id: 'deliveryDateTime',
      label: 'Delivery Date & Time',
      type: 'datetime',
      value: values.deliveryDateTime,
      onChange: handleDateChange('deliveryDateTime'),
      format: 'DD/MM/YYYY HH:mm',
      variant: 'dialog',
      clearable: true,
      inputVariant: 'outlined',
      grid: {
        xs: 12,
        lg: 5
      }
    },
    {
      ...defaultTextFieldProps,
      id: 'deliveryDocket',
      label: 'Delivery Docket',
      type: 'text',
      value: values.deliveryDocket,
      onChange: handleInputChange('deliveryDocket'),
      grid: {
        xs: 12,
        lg: 5
      }
    }
  ]

  const fieldsRight = [
    {
      ...defaultTextFieldProps,
      id: 'purchaseOrder',
      label: 'Purchase Order',
      type: 'text',
      required: true,
      value: values.purchaseOrder,
      onChange: handleInputChange('purchaseOrder'),
      error: values.isSubmitted && String(values.purchaseOrder).length === 0,
      grid: {
        xs: 12,
        lg: 4
      }
    },
    {
      ...defaultCheckboxProps,
      id: 'isRecurring',
      labelProps: {
        label: 'Standing'
      },
      type: 'switch',
      switchProps: {
        checked: values.isRecurring,
        onChange: handleSwitchChange('isRecurring'),
        color: 'primary'
      },
      grid: {
        xs: 12,
        lg: 4
      }
    },
    {
      ...defaultCheckboxProps,
      id: 'isPriority',
      labelProps: {
        label: 'Priority'
      },
      type: 'switch',
      switchProps: {
        checked: values.isPriority,
        onChange: handleSwitchChange('isPriority'),
        color: 'primary'
      },
      grid: {
        xs: 12,
        lg: 4
      }
    },
    {
      ...defaultTextFieldProps,
      id: 'quantity',
      label: 'Quantity',
      type: 'number',
      min: 0,
      max: 10000,
      value: values.quantity,
      onChange: handleInputChange('quantity'),
      grid: {
        xs: 12,
        lg: 4
      }
    },
    {
      ...defaultTextFieldProps,
      id: 'deliveryInstructions',
      label: 'Delivery Instructions',
      multiline: true,
      rows: 4,
      type: 'text',
      value: values.deliveryInstructions,
      onChange: handleInputChange('deliveryInstructions'),
      grid: {
        xs: 12,
        lg: 8
      }
    }
  ]

  const orderDate = values.orderDateTime ? moment(values.orderDateTime) : moment()

  const fieldMapper = ({ id, type, grid, ...props }) => {
    const gridProps = grid ? { ...grid } : { xs: 12 }
    return (
      <Grid key={id} item {...gridProps}>
        {type === 'static-text' && <Typography id={id} {...props} />}
        {type === 'text' && <TextField id={id} {...props} />}
        {type === 'number' && <TextField type='number' id={id} {...props} />}
        {type === 'switch' && <Switch id={id} {...props} />}
        {type === 'checkbox' && <Checkbox id={id} {...props} />}
        {type === 'datetime' && <DateTimePicker id={id} {...props} />}
      </Grid>
    )
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      dialogTitleStyle={{ backgroundColor: '#0d47a1', color: '#efefef' }}
      title={(
        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
          <div>
            {selectedOrder ? 'Purchase' : 'New'} Order{!!values.purchaseOrder && ':'} {values.purchaseOrder}
          </div>
          <div style={{ position: 'relative', borderRadius: 4, paddingLeft: 4, paddingRight: 4, paddingTop: 5 }}>
            <span style={{
              fontSize: 12,
              position: 'absolute',
              top: -10,
              left: 2
            }}
            >Order Received
            </span>
            <span style={{ display: 'inline-block', fontSize: 18 }}>{orderDate.format('Do MMM YYYY [@] h:mma')}</span>
          </div>
        </Box>
      )}
      buttons={buttons}
      dialogProps={{ fullWidth: true, maxWidth: 'md', disableBackdropClick: true, disableEscapeKeyDown: true }}
      progress={
        <>
          {isLoading && <span><em>Processing, please wait</em> ... <LoadingIndicator size={12} /></span>}
          {orderModifiedStatus.status === false && <Box color='error.main'><strong>{orderModifiedStatus.message}</strong></Box>}
        </>
      }
    >
      <Box mt={2} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2}>
            {fieldsLeft.map(fieldMapper)}
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2}>
            {fieldsRight.map(fieldMapper)}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </Modal>
  )
}

OrderDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
}

export default React.memo(OrderDialog, isPropsEqual)
