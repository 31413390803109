import React from 'react'
import { connect } from 'react-redux'
import isPropsEqual from 'react-fast-compare'
import StatsComponent from '../components/OrderStats'
import { makeSelectOrders } from '../lib/store/selectors/orders'

const OrderStats = React.memo(({ orders }) => {
  const quantityReducer = (acc, cur) => acc + parseInt(cur.quantity, 10)

  const ordersCount = orders?.length || 0
  const deliveredCount = orders?.length > 0
    ? orders.filter(o => !!o.deliveryDocket && !!o.deliveryDateTime && !!o.deliveryTeam).length
    : 0
  const quantity = orders?.reduce(quantityReducer, 0) || 0

  return <StatsComponent orders={ordersCount} delivered={deliveredCount} quantity={quantity} />
}, isPropsEqual)

const mapStateToProps = state => {
  const selectOrders = makeSelectOrders()
  return {
    orders: selectOrders(state)
  }
}

export default connect(mapStateToProps)(OrderStats)
