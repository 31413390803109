import { createReducer } from 'redux-create-reducer'
import * as types from '../actions/types'

const initialState = {
  selectedCustomerId: null,
  list: []
}

export const customers = createReducer(initialState, {
  [types.GET_CUSTOMERS_OK] (state, action) {
    const { customers: list } = action
    return {
      ...state,
      list: [...list]
    }
  },
  [types.DIALOG_CUSTOMER] (state, action) {
    const { customerId } = action
    return {
      ...state,
      selectedCustomerId: state.selectedCustomerId ? null : customerId
    }
  },
  [types.LOGOUT] () {
    return initialState
  }
})
