import React from 'react'
import PropTypes from 'prop-types'
import isPropsEqual from 'react-fast-compare'
import Grid from '@material-ui/core/Grid'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Modal from './Modal'

function UserDialog ({
  isOpen,
  onClose,
  onSubmit
}) {
  const initialValues = {
    name: '',
    email: '',
    password: '',
    role: 'user',
    isSubmitted: false
  }

  const [values, setValues] = React.useState(initialValues)

  React.useEffect(() => {
    if (!isOpen) {
      setValues({
        name: '',
        email: '',
        password: '',
        role: 'user',
        isSubmitted: false
      })
    }
  }, [isOpen, setValues])

  const isValidEmail = (email) => /(.+)@(.+){1,}\.(.+){1,}/.test(email)

  const handleChange = field => event => {
    const { value } = event.target
    setValues(vals => ({ ...vals, [field]: value }))
  }

  const handleClose = () => {
    setValues(initialValues)
    onClose()
  }

  const handleSubmit = () => {
    setValues(vals => ({ ...vals, isSubmitted: true }))
    const { name, email, role, password } = values
    if (String(name).length === 0) return
    if (String(email).length === 0 || !isValidEmail(email)) return

    onSubmit({ name, email, role, password })
  }

  const buttons = [
    {
      id: '1',
      onClick: handleClose,
      color: 'primary',
      children: 'Cancel',
      variant: 'outlined',
      style: { marginRight: 10 }
    },
    {
      id: '2',
      onClick: handleSubmit,
      color: 'primary',
      children: 'Submit',
      variant: 'contained'
    }
  ]

  const defaultTextFieldProps = {
    fullWidth: true,
    variant: 'outlined'
  }

  const fields = [
    {
      ...defaultTextFieldProps,
      autoFocus: true,
      id: 'name',
      label: 'Full Name',
      type: 'text',
      required: true,
      value: values.name,
      onChange: handleChange('name'),
      error: values.isSubmitted && String(values.name).length === 0
    },
    {
      ...defaultTextFieldProps,
      id: 'email',
      label: 'Email Address (for login)',
      type: 'email',
      required: true,
      value: values.email,
      onChange: handleChange('email'),
      error: values.isSubmitted && (String(values.email).length === 0 || !isValidEmail(values.email))
    },
    {
      ...defaultTextFieldProps,
      id: 'password',
      label: 'Password',
      type: 'password',
      required: true,
      value: values.password,
      onChange: handleChange('password'),
      error: values.isSubmitted && String(values.password).length === 0
    }
  ]

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title='New User Form'
      buttons={buttons}
      dialogProps={{ fullWidth: true, maxWidth: 'sm', disableBackdropClick: true, disableEscapeKeyDown: true }}
    >
      <DialogContentText>
        Fill out the fields to add a new user or admin.
      </DialogContentText>
      <Grid container spacing={2}>
        {fields.map(({ id, ...props }) => (
          <Grid key={id} item xs={12}>
            <TextField id={id} {...props} />
          </Grid>
        ))}
        <Grid item xs={12}>
          <FormControl component='fieldset'>
            <FormLabel component='legend' required>Role</FormLabel>
            <RadioGroup row aria-label='role' name='role' value={values.role} onChange={handleChange('role')}>
              <FormControlLabel
                value='user'
                control={<Radio color='primary' />}
                label='User'
              />
              <FormControlLabel
                value='admin'
                control={<Radio color='primary' />}
                label='Admin'
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Modal>
  )
}

UserDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
}

export default React.memo(UserDialog, isPropsEqual)
