import React from 'react'
import { connect } from 'react-redux'
import isPropsEqual from 'react-fast-compare'
import StatsComponent from '../components/DeliveryStats'
import { makeSelectLastDeliveries } from '../lib/store/selectors/orders'

const DeliveryStats = React.memo(({ lastDeliveries }) => {
  return <StatsComponent crewDeliveries={lastDeliveries} />
}, isPropsEqual)

const mapStateToProps = state => {
  const selectLastDeliveries = makeSelectLastDeliveries()
  return {
    lastDeliveries: selectLastDeliveries(state)
  }
}

export default connect(mapStateToProps)(DeliveryStats)
