const { REACT_APP_API_HOST: apiHost } = process.env

export const API_ENDPOINTS = {
  /* Auth */
  login: `${apiHost}/login`,
  changePassword: `${apiHost}/changePassword`,
  refreshIdToken: `${apiHost}/refreshIdToken`,
  /* Orders */
  orders: `${apiHost}/orders/:ORDER_ID`,
  lastDeliveries: `${apiHost}/lastDeliveries`,
  /* Users */
  users: `${apiHost}/users/:USER_ID`,
  userStatus: `${apiHost}/users/:USER_ID/status`

}
