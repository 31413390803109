import { createReducer } from 'redux-create-reducer'
import * as types from '../actions/types'

const initialState = {
  addUser: false,
  order: false,
  product: false,
  customer: false,
  ordersCSVExport: false
}

export const dialogs = createReducer(initialState, {
  [types.DIALOG_EXPORT_ORDERS_CSV] (state) {
    return {
      ...state,
      ordersCSVExport: !state.ordersCSVExport
    }
  },
  [types.DIALOG_ADD_USER] (state) {
    return {
      ...state,
      addUser: !state.addUser
    }
  },
  [types.DIALOG_ORDER] (state) {
    return {
      ...state,
      order: !state.order
    }
  },
  [types.DIALOG_PRODUCT] (state) {
    return {
      ...state,
      product: !state.product
    }
  },
  [types.DIALOG_CUSTOMER] (state) {
    return {
      ...state,
      customer: !state.customer
    }
  },
  [types.LOGOUT] () {
    return initialState
  }
})
