import React from 'react'
import PropTypes from 'prop-types'
import sort from 'fast-sort'
import Grid from '@material-ui/core/Grid'
import Table from './TableTemplateFilter'
import UserTableRow from './UserTableRow'

function UserTable ({ me, users, isLoading, onDelete, oToggleStatus }) {
  const initalState = {
    sortDirection: 'asc',
    sortBy: 'name',
    filter: '',
    usersData: []
  }

  const [values, setValues] = React.useState(initalState)

  React.useEffect(() => {
    setValues(vals => ({
      ...vals,
      usersData: [...users]
    }))
  }, [users])

  const handleRequestSort = (_, property) => {
    if (property === 'toggle' || property === 'delete') return
    setValues(vals => ({
      ...vals,
      sortDirection: vals.sortDirection === 'asc' ? 'desc' : 'asc',
      sortBy: property
    }))
  }

  const handleFilter = event => {
    const { value } = event.target
    setValues(vals => ({ ...vals, filter: value }))
  }

  const handleToggleUserStatus = (id, isActive) => {
    oToggleStatus(id, isActive)
  }

  const handleDeleteUser = (id) => {
    onDelete(id)
  }

  const renderRow = ({ rowData }) => { // eslint-disable-line
    // eslint-disable-next-line
    return <UserTableRow {...rowData} isMe={rowData.email === me.email} onToggle={handleToggleUserStatus} onDelete={handleDeleteUser} />
  }

  const tableHeaders = [
    { id: 'name', label: 'Name' },
    { id: 'email', label: 'Email Address' },
    { id: 'role', label: 'Role' },
    { id: 'isActive', label: 'Status' },
    { id: 'toggle', label: 'Toggle Status' },
    { id: 'delete', label: 'Delete User' }
  ]

  const usersData = sort([...values.usersData.filter(u => {
    return String(u.name).toLowerCase().includes(values.filter) ||
    String(u.email).toLowerCase().includes(values.filter)
  })]).by({
    [values.sortDirection]: u => u[values.sortBy]
  })

  return (
    <Grid container spacing={2} style={{ paddingTop: 20 }}>
      <Grid item xs={12}>
        <Table
          isLoading={isLoading}
          tableData={usersData}
          sortedHeaders
          tableHeaders={tableHeaders}
          sortDirection={values.sortDirection}
          sortBy={values.sortBy}
          onRequestSort={handleRequestSort}
          rowRenderer={renderRow}
          onFilter={handleFilter}
          filterText={values.filter}
          filterPlaceholder='Filter by name or email'
          filterLabel='Filter Users'
        />
      </Grid>
    </Grid>
  )
}

UserTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    isActive: PropTypes.bool
  })),
  me: PropTypes.shape(),
  isLoading: PropTypes.bool,
  onDelete: PropTypes.func,
  oToggleStatus: PropTypes.func
}

export default UserTable
