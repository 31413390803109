export const CUSTOMERS = [
  {
    id: 1, // Customer Id
    name: 'Alex & Co',
    accountOutstanding: false
  },
  {
    id: 2, // Customer Id
    name: 'Kate & Co',
    accountOutstanding: false
  }
]
