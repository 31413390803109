import { createReducer } from 'redux-create-reducer'
import sort from 'fast-sort'
import moment from 'moment'
import * as types from '../actions/types'
import { ORDER_STATUS } from '../../constants/order-status'
import 'moment/min/locales'

moment.locale('en-AU')

const initialState = {
  selectedOrderId: null,
  list: [],
  orderModified: {
    status: null,
    message: ''
  },
  lastDeliveries: []
}

/**
   *
   * Order Coloring
   * - orders from previous days are RED
   * - standing orders are GREEN
   * - normal orders not delivered are BLACK
   * - delivered orders are TICKED (in red)
   *
   * Order Sorting (sorted from top to bottom)
   * 1) RED orders
   * 2) GREEN orders
   * 3) Priority orders
   * 4) BLACK orders
   * 5) Delivered orders
   *
   * Priority Tick defaults
   * - all RED orders
   * - all GREEN orders
   *
   *  */
const orderColorMapping = order => {
  const {
    orderDateTime,
    isRecurring,
    deliveryTeam,
    deliveryDocket,
    deliveryDateTime
  } = order

  const isDelivered = !!deliveryTeam && !!deliveryDocket && !!deliveryDateTime
  let rowStatusColor

  if (moment(orderDateTime).isBefore(moment().startOf('day')) && !isRecurring) { // orders from previous days are RED
    rowStatusColor = ORDER_STATUS.red
    order.isPriority = true
  } else if (isRecurring) { //  standing orders are GREEN
    rowStatusColor = ORDER_STATUS.green
    order.isPriority = true
  } else if (!isDelivered) { // normal orders not delivered are BLACK
    rowStatusColor = ORDER_STATUS.black
  }

  return {
    rowStatusColor,
    ...order
  }
}

const sortOrders = orders => {
  const receivedSortedOrders = sort([...orders]).by({
    asc: o => new Date(o.orderDateTime)
  })

  const isPrioritySortedOrders = sort([...receivedSortedOrders]).by({
    desc: o => (o.isPriority || 0)
  })

  const orderColorImportance = { [ORDER_STATUS.red]: 3, [ORDER_STATUS.green]: 2, [ORDER_STATUS.black]: 1 }
  const colorSortedOrders = sort([...isPrioritySortedOrders]).by({
    desc: odr => (orderColorImportance[odr.rowStatusColor] || 0)
  })

  const deliverySortedOrders = sort([...colorSortedOrders]).by({
    asc: o => o.deliveryDateTime && o.deliveryDocket ? moment(o.deliveryDateTime, 'YYYY-MM-DD HH:mm:ss').toDate() : 0
  })

  return deliverySortedOrders
}

export const orders = createReducer(initialState, {
  [types.UPDATE_ORDER_OK] (state, action) {
    const { order } = action
    const colorMappedOrder = orderColorMapping(order)
    return {
      ...state,
      list: sortOrders(state.list.map(o => {
        if (o.id === order.id) return { ...colorMappedOrder }
        return { ...o }
      })),
      orderModified: {
        status: true,
        message: ''
      }
    }
  },
  [types.ADD_ORDER_OK] (state, action) {
    const { order } = action
    const colorMappedOrder = orderColorMapping(order)
    return {
      ...state,
      list: sortOrders([...state.list, { ...colorMappedOrder }]),
      orderModified: {
        status: true,
        message: ''
      }
    }
  },
  [types.MODIFY_ORDER_FAIL] (state, action) {
    const { failMessage } = action
    return {
      ...state,
      orderModified: {
        status: false,
        message: failMessage
      }
    }
  },
  [types.RESET_MODIFY_ORDER] (state) {
    return {
      ...state,
      orderModified: {
        status: null,
        message: ''
      }
    }
  },
  [types.GET_ORDERS_OK] (state, action) {
    const { orders } = action
    const colorMappedOrders = orders.map(orderColorMapping)
    return {
      ...state,
      list: sortOrders([...colorMappedOrders])
    }
  },
  [types.GET_LAST_DELIVERIES_OK] (state, action) {
    const { lastDeliveries } = action
    return {
      ...state,
      lastDeliveries: sort([...lastDeliveries]).by({
        asc: o => {
          try {
            const team = parseInt(o.deliveryTeam, 10)
            if (Number.isInteger(team)) return team
            return o.deliveryTeam
          } catch (err) {
            return o.deliveryTeam
          }
        }
      })
    }
  },
  [types.DIALOG_ORDER] (state, action) {
    const { orderId } = action
    return {
      ...state,
      selectedOrderId: state.selectedOrderId ? null : orderId
    }
  },
  [types.LOGOUT] () {
    return initialState
  }
})
