export const APP_ROUTES = {
  login: '/login',
  logout: '/logout',
  notfound: '/notfound',
  orders: '/orders',
  orderstable: '/orderstable',
  products: '/products',
  customers: '/customers',
  deliverycrew: '/deliverycrew',
  placeholder: '/placeholder',
  profile: '/profile',
  usersetup: '/usersetup'
}
