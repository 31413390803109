import { authRequest } from './apiSetup'
import { API_ENDPOINTS } from '../../constants/api-endpoints'

/*
 * Orders API
 ** */

// const delay = (ms) => new Promise(resolve => setTimeout(() => resolve(true), ms))

export function getOrders (idToken) {
  const endpoint = API_ENDPOINTS.orders
    .replace(/:ORDER_ID/, '')
    .replace(/\/$/, '')

  return authRequest(idToken, { method: 'GET', endpoint })
    .then(response => {
      return [...response.orders]
    })
    .catch(error => {
      throw new Error(error.message || 'There was a problem processing the request')
    })
}

export function addOrder (idToken, { order }) {
  const endpoint = API_ENDPOINTS.orders
    .replace(/:ORDER_ID/, '')
    .replace(/\/$/, '')

  return authRequest(idToken, { method: 'POST', endpoint, body: { ...order } })
    .then(response => {
      return response.order_id
    })
    .catch(error => {
      throw new Error(error.message || 'There was a problem processing the request')
    })
}

export function updateOrder (idToken, { orderId, order }) {
  const endpoint = API_ENDPOINTS.orders
    .replace(/:ORDER_ID/, orderId)
    .replace(/\/$/, '')

  return authRequest(idToken, { method: 'PUT', endpoint, body: { ...order } })
    .then(response => {
      return response.updated === true
    })
    .catch(error => {
      throw new Error(error.message || 'There was a problem processing the request')
    })
}

export function getOrdersByDateRange (idToken, { fromDate, toDate }) {
  const endpoint = API_ENDPOINTS.orders
    .replace(/:ORDER_ID/, 'range')

  return authRequest(idToken, { method: 'GET', endpoint: `${endpoint}/${fromDate}_${toDate}` })
    .then(response => {
      return response.orders
    })
    .catch(error => {
      throw new Error(error.message || 'There was a problem processing the request')
    })
}

export function getLastDeliveryTimes (idToken) {
  const endpoint = API_ENDPOINTS.lastDeliveries

  return authRequest(idToken, { method: 'GET', endpoint })
    .then(response => {
      return [...response.lastDeliveries]
    })
    .catch(error => {
      throw new Error(error.message || 'There was a problem processing the request')
    })
}
