// import { authRequest } from './apiSetup'
// import { API_ENDPOINTS } from '../../constants/api-endpoints'
import { CUSTOMERS } from './customersMockData'

/*
 * Customers API
 ** */

const delay = (ms) => new Promise(resolve => setTimeout(() => resolve(true), ms))

export function getCustomers (idToken) {
  console.log('idToken:', idToken)
  return delay(500).then(() => CUSTOMERS)
}
