import { createReducer } from 'redux-create-reducer'
import * as types from '../actions/types'

const initialState = []

function filterId (state, action) {
  return state.filter(req => req.id !== action.apiReqId)
}

export const apiRequest = createReducer(initialState, {
  [types.API_REQUEST]: (state, action) => [
    ...state,
    { id: action.id, message: action.message, requestType: action.requestType }
  ],
  [types.API_END]: (state, action) => filterId(state, action),
  /* Auth */
  [types.LOGIN_OK]: (state, action) => filterId(state, action),
  [types.CHANGE_PASSWORD_OK]: (state, action) => filterId(state, action),
  /* Export Orders */
  [types.GET_CSV_EXPORT_OK]: (state, action) => filterId(state, action),
  /* Orders */
  [types.GET_ORDERS_OK]: (state, action) => filterId(state, action),
  [types.ADD_ORDER_OK]: (state, action) => filterId(state, action),
  [types.UPDATE_ORDER_OK]: (state, action) => filterId(state, action),
  [types.GET_LAST_DELIVERIES_OK]: (state, action) => filterId(state, action),
  /* Users */
  [types.GET_USERS_OK]: (state, action) => filterId(state, action),
  [types.ADD_USER_OK]: (state, action) => filterId(state, action)
})
