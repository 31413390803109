import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import signInBgImage from '../_assets/images/drawer-bg.jpg'
import Login from '../containers/Login'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    paddingBottom: theme.spacing(2),
    borderRadius: 10
  },
  bg: {
    backgroundColor: '#fff',
    minHeight: '100vh',
    backgroundImage: `url(${signInBgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right center'
  }
}))

export default function LoginPage () {
  const classes = useStyles()

  return (
    <Box className={classes.bg}>
      <Container component='main' maxWidth='xs' className={classes.container}>
        <Login />
      </Container>
    </Box>

  )
}
