import React from 'react'
import { compose } from 'recompose'
import Protected from '../containers/Protected'
import Orders from '../containers/Orders'

const OrdersTablePage = () => {
  return <Orders fullscreen />
}

export default compose(
  Protected((role) => role === 'admin' || role === 'user')
)(OrdersTablePage)
