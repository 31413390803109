/* eslint-disable react/display-name */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import isPropsEqual from 'react-fast-compare'
import TableCellComponent from '@material-ui/core/TableCell'
import TableRowComponent from '@material-ui/core/TableRow'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import Chip from '@material-ui/core/Chip'
import Switch from '@material-ui/core/Switch'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import FaceIcon from '@material-ui/icons/Face'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles(() => ({
  capitalize: {
    textTransform: 'capitalize'
  },
  bold: {
    fontWeight: 'bold'
  }
}))

const TableCell = React.memo((props) =>
  <TableCellComponent {...props} />
, isPropsEqual)

const TableRow = React.memo((props) =>
  <TableRowComponent {...props} />
, isPropsEqual)

function UserTableRow ({ id, name, email, role, isMe, isActive, onToggle, onDelete }) {
  const classes = useStyles()

  const handleToggle = () => {
    onToggle(id, !isActive)
  }

  const handleDelete = () => {
    onDelete(id)
  }

  return (
    <TableRow key={id}>
      <TableCell>
        <Typography component='span' variant='body2' className={classes.bold} color={!isActive ? 'textSecondary' : undefined}>
          {name} {isMe ? '(Me)' : ''}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography component='span' variant='body2' color={!isActive ? 'textSecondary' : undefined}>
          {email}
        </Typography>
      </TableCell>
      <TableCell className={classes.capitalize}>
        <Tooltip title={role === 'admin' ? 'Admin has access to orders data, user setup and create/update orders' : 'User has access to create/update orders'} placement='top'>
          <Chip
            label={role}
            variant='outlined'
            icon={role === 'admin' ? <FaceIcon /> : undefined}
            avatar={role === 'user' ? <Avatar>U</Avatar> : undefined}
            color={role === 'admin' ? 'secondary' : 'primary'}
          />
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={isActive ? 'User can login' : 'User cannot login'} placement='top'>
          <Chip
            variant={!isActive ? 'outlined' : undefined}
            icon={isActive ? <DoneIcon fontSize='small' /> : <ClearIcon fontSize='small' />}
            color={isActive ? 'primary' : 'default'}
            label={isActive ? 'Active' : 'Not Active'}
          />
        </Tooltip>
      </TableCell>
      <TableCell>
        <Switch
          color='primary'
          disabled={isMe}
          checked={isActive}
          onChange={handleToggle}
          name={`${id}-${name}`}
        />
      </TableCell>
      <TableCell>
        <IconButton aria-label='delete' disabled={isMe} onClick={handleDelete}>
          <DeleteIcon fontSize='small' />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

UserTableRow.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  email: PropTypes.string,
  role: PropTypes.string,
  isMe: PropTypes.bool,
  isActive: PropTypes.bool,
  onToggle: PropTypes.func,
  onDelete: PropTypes.func
}

export default React.memo(UserTableRow, isPropsEqual)
