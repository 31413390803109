import { authRequest } from './apiSetup'
import { API_ENDPOINTS } from '../../constants/api-endpoints'

/*
 * Users API
 ** */

// const delay = (ms) => new Promise(resolve => setTimeout(() => resolve(true), ms))

export function getUsers (idToken) {
  const endpoint = API_ENDPOINTS.users
    .replace(/:USER_ID/, '')
    .replace(/\/$/, '')

  return authRequest(idToken, { method: 'GET', endpoint })
    .then(response => {
      return [...response.users]
    })
    .catch(error => {
      throw new Error(error.message || 'There was a problem processing the request')
    })
}

export function addUser (idToken, { name, email, role, password }) {
  const endpoint = API_ENDPOINTS.users.replace(/:USER_ID/, '').replace(/\/$/, '')

  return authRequest(idToken,
    {
      method: 'POST',
      endpoint,
      body: {
        name,
        email,
        role,
        password,
        isActive: true
      }
    })
    .then(response => {
      const { user_id: userId } = response
      return { id: parseInt(userId, 10) }
    })
    .catch(error => {
      // HTTP conflict, email address exists
      if (error.response && error.response.status === 409) {
        throw new Error('Email address already exists')
      } else {
        throw new Error(error.message || 'There was a problem processing the request')
      }
    })
}

export function deleteUser (idToken, { userId }) {
  const endpoint = API_ENDPOINTS.users.replace(/:USER_ID/, userId)

  return authRequest(idToken, { method: 'DELETE', endpoint })
    .then(response => {
      const { deleted } = response
      return deleted
    })
    .catch(error => {
      throw new Error(error.message || 'There was a problem processing the request')
    })
}

export function toggleUserStatus (idToken, { userId, isActive }) {
  const endpoint = API_ENDPOINTS.userStatus.replace(/:USER_ID/, userId)

  return authRequest(idToken, { method: 'PUT', endpoint, body: { isActive } })
    .then(response => {
      const { updated } = response
      return updated
    })
    .catch(error => {
      throw new Error(error.message || 'There was a problem processing the request')
    })
}
