import Papa from 'papaparse'

export const downloadCSV = (args) => {
  const filename = args.filename || 'export.csv'
  const columns = args.columns || null

  const csv = Papa.unparse({ data: args.data, fields: columns })
  if (csv == null) return

  var blob = new Blob([csv]) // eslint-disable-line

  // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, args.filename)
  } else {
    var a = window.document.createElement('a')
    a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
    a.download = filename
    document.body.appendChild(a)
    a.click() // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
    document.body.removeChild(a)
  }
}
