import React from 'react'
import isPropsEqual from 'react-fast-compare'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MUISwitch from '@material-ui/core/Switch'

function Switch ({ switchProps, labelProps }) {
  return (
    <FormControlLabel
      control={
        <MUISwitch
          {...switchProps}
        />
      }
      {...labelProps}
    />
  )
}

export default React.memo(Switch, isPropsEqual)
