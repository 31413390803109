import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import { DRAWER_WIDTH } from './DashboardLayout'

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  }
}))

const AppToolbar = ({ isDrawerOpen, onClickOpenDrawer, onClickCloseDrawer }) => {
  const classes = useStyles()

  return (
    <AppBar
      position='absolute'
      className={clsx(classes.appBar, isDrawerOpen && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge='start'
          color='inherit'
          aria-label='Toggle drawer'
          onClick={isDrawerOpen ? onClickCloseDrawer : onClickOpenDrawer}
          className={classes.menuButton}
        >
          {isDrawerOpen ? <FirstPageIcon /> : <MenuIcon />}
        </IconButton>
        <Typography component='h1' variant='h6' color='inherit' noWrap className={classes.title}>
          Orders Dashboard
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

AppToolbar.propTypes = {
  isDrawerOpen: PropTypes.bool,
  onClickOpenDrawer: PropTypes.func,
  onClickCloseDrawer: PropTypes.func
}

export default AppToolbar
