import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import InitOrders from './InitOrders'
// import InitProducts from './InitProducts'
// import InitCustomers from './InitCustomers'
import { makeSelectIsAuth } from '../../lib/store/selectors/auth'

function Init ({ isAuth }) {
  return (
    <>
      {isAuth && <InitOrders />}
      {/* {isAuth && <InitProducts />} */}
      {/* {isAuth && <InitCustomers />} */}
    </>
  )
}

Init.propTypes = {
  isAuth: PropTypes.bool
}

const mapStateToProps = state => {
  const selectIsAuth = makeSelectIsAuth()
  return {
    isAuth: selectIsAuth(state)
  }
}

export default connect(mapStateToProps)(Init)
