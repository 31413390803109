import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import LoadingIndicator from '../LoadingIndicator'
// import Grid from '@material-ui/core/Grid'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Checkbox from '@material-ui/core/Checkbox'

function Copyright () {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      {new Date().getFullYear()}
      {' - '}
      <Link color='inherit' href='http://purewater.com.pg' rel='noreferrer noopener'>
        Purewater
      </Link>
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  mt: {
    paddingTop: theme.spacing(8)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  submitIcon: {
    margin: theme.spacing(0, 1)
  }
}))

function LoginForm ({ onSubmit, isLoading }) {
  const classes = useStyles()
  const [values, setValues] = React.useState({
    email: '',
    password: '',
    isSubmitted: false
  })

  const handleChange = field => event => {
    const { value } = event.target
    setValues(vals => ({ ...vals, [field]: value }))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setValues(vals => ({ ...vals, isSubmitted: true }))
    if (!isEmail(values.email) || String(values.password).length === 0) return
    onSubmit({ email: values.email, password: values.password })
  }

  const isEmail = value => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(value).toLowerCase())
  }

  const fields = [
    {
      id: 'email',
      name: 'email',
      label: 'Email Address',
      required: true,
      autoComplete: 'email',
      autoFocus: true,
      value: values.email,
      onChange: handleChange('email'),
      error: values.isSubmitted && !isEmail(values.email),
      helperText: values.isSubmitted && !isEmail(values.email) ? 'Invalid email' : null
    },
    {
      id: 'password',
      name: 'password',
      label: 'Password',
      type: 'password',
      required: true,
      autoComplete: 'current-password',
      value: values.password,
      onChange: handleChange('password'),
      error: values.isSubmitted && String(values.password).length === 0,
      helperText: values.isSubmitted && String(values.password).length === 0 ? 'Invalid email' : null
    }
  ]

  return (
    <div className={classes.mt}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Orders Dashboard
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          {fields.map(({ id, ...fieldProps }) => (
            <TextField
              key={id}
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id={id}
              {...fieldProps}
            />
          ))}
          {/* <FormControlLabel
            control={<Checkbox value='remember' color='primary' />}
            label='Remember me'
          /> */}
          <Button
            disabled={isLoading}
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
          >
            {isLoading && <LoadingIndicator size={24} />}
            {!isLoading && (
              <>
                <LockOutlinedIcon className={classes.submitIcon} />
                  Login
              </>
            )}
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link href='#' variant='body2'>
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href='#' variant='body2'>
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </div>
  )
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool
}

export default LoginForm
