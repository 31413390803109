import { createReducer } from 'redux-create-reducer'
import * as types from '../actions/types'

const initialState = []

export const notifications = createReducer(initialState, {
  [types.ENQUEUE_NOTIFICATION] (state, action) {
    return [
      ...state,
      {
        key: action.key,
        ...action.notification
      }
    ]
  },
  [types.CLOSE_NOTIFICATION] (state, action) {
    return state.map(notification =>
      action.dismissAll || notification.key === action.key
        ? { ...notification, dismissed: true }
        : { ...notification }
    )
  },
  [types.REMOVE_NOTIFICATION] (state, action) {
    return state.filter(notification => notification.key !== action.key)
  },
  [types.LOGOUT] () {
    return initialState
  }
})
