import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import AppToolbar from './AppToolbar'
import DashboardNavigation from '../containers/DashboardNavigation'

export const DRAWER_WIDTH = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
}))

export default function DashboardLayout ({
  version,
  isDrawerOpen,
  onDrawerOpen,
  onDrawerClose,
  children
}) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AppToolbar
        isDrawerOpen={isDrawerOpen}
        onClickOpenDrawer={onDrawerOpen}
        onClickCloseDrawer={onDrawerClose}
      />
      <DashboardNavigation isDrawerOpen={isDrawerOpen} version={version} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          {children}
        </Container>
      </main>
    </div>
  )
}

DashboardLayout.propTypes = {
  version: PropTypes.string,
  isDrawerOpen: PropTypes.bool,
  onDrawerOpen: PropTypes.func,
  onDrawerClose: PropTypes.func,
  children: PropTypes.node
}
