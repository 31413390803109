import { createReducer } from 'redux-create-reducer'
import * as types from '../actions/types'

const initialState = []

export const users = createReducer(initialState, {
  [types.GET_USERS_OK] (_, action) {
    const { users } = action
    return [...users]
  },
  [types.ADD_USER_OK] (state, action) {
    const { user } = action
    return [...state, user]
  },
  [types.DELETE_USER] (state, action) {
    const { userId } = action
    return state.filter(u => u.id !== userId)
  },
  [types.TOGGLE_USER_STATUS] (state, action) {
    const { userId, isActive } = action
    return state.map(u => {
      if (u.id === userId) {
        return { ...u, isActive }
      }
      return { ...u }
    })
  },
  [types.LOGOUT] () {
    return initialState
  }
})
