import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import OrderCSVExportDialog from '../components/OrderCSVExportDialog'
import { toggleExportOrdersCSVDialog } from '../lib/store/actions/dialogs'
import { getOrdersByDateRange, resetExportOrders } from '../lib/store/actions/exportOrders'
import { makeSelectIsExportOrderCSVDialogOpen } from '../lib/store/selectors/dialogs'
import { selectExportOrders, selectHasExportOrders } from '../lib/store/selectors/exportOrders'
import { selectIsDoingGetExportOrdersAPIRequest } from '../lib/store/selectors/api-request'
import { downloadCSV } from '../lib/CSVExport'

function OrderExportDialog ({ isOpen, hasFoundOrders, onToggleDialog, onResetExportData, orders, onGetOrders, isLoading }) {
  const initialValues = {
    fromDate: null,
    toDate: null
  }

  const [values, setValues] = React.useState(initialValues)

  React.useEffect(() => {
    const { fromDate, toDate } = values
    if (orders?.length > 0 && fromDate && toDate) {
      downloadCSV({
        filename: `orders-${fromDate}-${toDate}.csv`,
        data: orders.map(o => ({
          orderDateTime: o.orderDateTime,
          purchaseOrder: o.purchaseOrder,
          priority: o.isPriority ? 'yes' : 'no',
          standingOrder: o.isRecurring ? 'yes' : 'no',
          customer: o.customer,
          customerPhone: o.customerPhone || '',
          accountValid: o.isAccountValid ? 'yes' : 'no',
          cash: o.isCash ? 'yes' : 'no',
          quantity: o.quantity,
          deliveryInstructions: o.deliveryInstructions,
          deliveryCrew: o.deliveryTeam,
          deliveryDateTime: o.deliveryDateTime,
          deliveryDocket: o.deliveryDocket
        })),
        columns: ['orderDateTime', 'purchaseOrder', 'priority', 'standingOrder', 'customer', 'customerPhone', 'accountValid', 'cash', 'quantity', 'deliveryInstructions', 'deliveryCrew', 'deliveryDateTime', 'deliveryDocket']
      })
    }
  }, [orders, values, onToggleDialog])

  const handleSubmit = ({ fromDate, toDate }) => {
    setValues(vals => ({ ...vals, fromDate, toDate }))
    onGetOrders({ fromDate, toDate })
  }

  const handleClose = () => {
    onToggleDialog()
    onResetExportData()
  }

  return <OrderCSVExportDialog hasFoundOrders={hasFoundOrders} isLoading={isLoading} isOpen={isOpen} onClose={handleClose} onSubmit={handleSubmit} />
}

OrderExportDialog.propTypes = {
  isOpen: PropTypes.bool,
  onToggleDialog: PropTypes.func,
  onAddUser: PropTypes.func
}

const mapStateToProps = state => {
  const selectIsDialogOpen = makeSelectIsExportOrderCSVDialogOpen()
  return {
    isOpen: selectIsDialogOpen(state),
    orders: selectExportOrders(state),
    hasFoundOrders: selectHasExportOrders(state),
    isLoading: selectIsDoingGetExportOrdersAPIRequest(state)
  }
}

export default connect(
  mapStateToProps,
  {
    onToggleDialog: toggleExportOrdersCSVDialog,
    onGetOrders: getOrdersByDateRange,
    onResetExportData: resetExportOrders
  }
)(OrderExportDialog)
