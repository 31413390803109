import React from 'react'
import moment from 'moment'
import isEqual from 'react-fast-compare'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import DownloadIcon from '@material-ui/icons/SystemUpdateAlt'
import RefreshIcon from '@material-ui/icons/Refresh'
import AddIcon from '@material-ui/icons/Add'
import { teal } from '@material-ui/core/colors'
import Typography from '@material-ui/core/Typography'
import { IconButton } from './forms/Button'
import FilterTable from './TableTemplateFilter'
import OrdersTableRow from './OrdersTableRow'
import OrderStats from '../containers/OrderStats'
import DeliveryStats from '../containers/DeliveryStats'
import LoadingIndicator from './LoadingIndicator'
import 'moment/min/locales'

moment.locale('en-AU')

const Table = React.memo(FilterTable, isEqual)
const TableRow = React.memo(OrdersTableRow, isEqual)

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(teal[500]),
    backgroundColor: teal[500],
    '&:hover': {
      backgroundColor: teal[700]
    }
  }
}))(Button)

function AddNewButton ({ onClick, tooltip, label, icon, disabled }) {
  return (
    <Tooltip title={tooltip} placement='top'>
      <div>
        <ColorButton
          onClick={onClick}
          variant='contained'
          size='small'
          startIcon={icon}
          disabled={disabled}
        >
          {label}
        </ColorButton>
      </div>
    </Tooltip>
  )
}

function OrdersTable ({
  customerOrders,
  isLoading,
  fullscreen,
  onClickExport,
  onClickNewOrder,
  onClickOrder,
  onClickPopOut,
  onClickRefresh
}) {
  const initalState = {
    filter: '',
    orders: []
  }

  const [values, setValues] = React.useState(initalState)

  React.useEffect(() => {
    setValues(vals => ({
      ...vals,
      orders: [...customerOrders]
    }))
  }, [customerOrders])

  const handleFilter = event => {
    const { value } = event.target
    setValues(vals => ({ ...vals, filter: value }))
  }

  const handleClickOrder = orderId => () => {
    onClickOrder(orderId)
  }

  const renderRow = ({ rowData }) => {
    const {
      id,
      deliveryInstructions,
      quantity,
      customer,
      customerPhone,
      isAccountValid,
      isCash,
      deliveryDateTime,
      deliveryDocket,
      deliveryTeam,
      isPriority,
      purchaseOrder,
      isRecurring,
      orderDateTime,
      rowStatusColor
    } = rowData

    return (
      <TableRow
        disabled={isLoading}
        rowStatusColor={rowStatusColor}
        onClick={handleClickOrder(id)}
        isAccountValid={isAccountValid}
        isCash={isCash}
        quantity={quantity}
        deliveryInstructions={deliveryInstructions}
        deliveryDateTime={deliveryDateTime ? moment(deliveryDateTime).format('DD/MM/YYYY [@] HH:mm') : ''}
        deliveryDocket={deliveryDocket}
        deliveryTeam={deliveryTeam}
        name={customer}
        phone={customerPhone ?? ''}
        isPriority={isPriority}
        purchaseOrder={isRecurring ? 'STAND' : purchaseOrder}
        orderDateTime={moment(orderDateTime).format('DD/MM/YYYY [@] HH:mm')}
      />
    )
  }

  const tableHeaders = [
    { id: 'isPriority', label: 'Priority' },
    { id: 'quantity', label: '19' },
    { id: 'name', label: 'Customer / Delivery Instructions' },
    { id: 'purchaseOrder', label: 'P.O' },
    { id: 'received', label: 'Received' },
    { id: 'accountOutstanding', label: 'Account' },
    { id: 'isCash', label: 'Cash' },
    { id: 'deliveryTeam', label: 'Crew' },
    { id: 'deliveryDate', label: 'Delivery' },
    { id: 'deliveryDocket', label: 'Docket' }
  ]

  const filterProps = fullscreen
    ? {
      filter: false
    }
    : {
      filter: true,
      onFilter: handleFilter,
      filterText: values.filter,
      filterPlaceholder: 'Filter by priority (e.g. priority:yes), standing (e.g. standing:yes), customer name, purchase order, crew (e.g. crew:1), or delivery docket',
      filterLabel: 'Filter orders'
    }

  const filteredOrders = values.filter.length > 0
    ? values.orders.filter(o => {
      const isPriority = String(`priority:${o.isPriority ? 'yes' : 'no'}`).toLowerCase().indexOf(String(values.filter).toLowerCase()) >= 0
      const isCustomer = String(o.customer).toLowerCase().indexOf(String(values.filter).toLowerCase()) >= 0
      const isPO = String(o.purchaseOrder).toLowerCase().indexOf(String(values.filter).toLowerCase()) >= 0
      const isRecurring = String(`standing:${o.isRecurring ? 'yes' : 'no'}`).toLowerCase().indexOf(String(values.filter).toLowerCase()) >= 0
      const isCrew = String(`crew:${o.deliveryTeam}`).toLowerCase().indexOf(String(values.filter).toLowerCase()) >= 0
      const isDocket = String(o.deliveryDocket).toLowerCase().indexOf(String(values.filter).toLowerCase()) >= 0

      return isPriority || isCustomer || isPO || isCrew || isDocket || isRecurring
    })
    : values.orders

  return (
    <>
      <Box mt={1} width='100%' display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
        {!fullscreen && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box display='flex' flexDirection='row' alignItems='center' justifyContent='flex-start'>
                <AddNewButton
                  label='New Order'
                  tooltip='Create a new order'
                  onClick={onClickNewOrder}
                  icon={<AddIcon />}
                  disabled={isLoading}
                />
                <Box mx={1} />
                <IconButton
                  label='Refresh Orders List'
                  onClick={onClickRefresh}
                  icon={<RefreshIcon />}
                  color='primary'
                  disabled={isLoading}
                />
                <Box mx={1} />
                {isLoading && (
                  <Typography component='div' variant='body2'>
                    <em>Loading, please wait ...</em> <LoadingIndicator size={15} />
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box style={{ paddingTop: 7.5, paddingBottom: 7.5 }} width='100%' display='flex' flexDirection='row' alignItems='center' justifyContent='center'>
                <OrderStats />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box display='flex' flexDirection='row' justifyContent='flex-end' alignItems='center'>
                <IconButton
                  label='Pop-out Orders Table'
                  onClick={onClickPopOut}
                  icon={<OpenInNewIcon />}
                  disabled={isLoading}
                />
                <IconButton
                  label='Export Orders to CSV'
                  onClick={onClickExport}
                  icon={<DownloadIcon />}
                  disabled={isLoading}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <DeliveryStats />
            </Grid>
          </Grid>
        )}
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table
            tableData={filteredOrders}
            tableHeaders={tableHeaders}
            rowRenderer={renderRow}
            {...filterProps}
            emtpyTable={<p>{isLoading ? 'Loading ...' : 'No Orders Found'}</p>}
            fillPaginationRows={false}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default React.memo(OrdersTable)
