import * as types from './types'
import { ordersApi } from '../../api'
import { apiRequest } from './api-request'
import { getAuthToken, checkHttpError } from './authTokenHelper'

export const resetExportOrders = () => ({
  type: types.RESET_CSV_EXPORT
})

export const getOrdersByDateRange = ({ fromDate, toDate }) => (dispatch, getState) => {
  dispatch(resetExportOrders())
  const apiReqAction = apiRequest({ requestType: types.EXPORT_ORDERS_REQ })
  dispatch(apiReqAction)

  ordersApi
    .getOrdersByDateRange(getAuthToken(getState()), { fromDate, toDate })
    .then(
      orders => {
        dispatch({
          type: types.GET_CSV_EXPORT_OK,
          orders,
          apiReqId: apiReqAction.id
        })
      },
      error => {
        throw new Error(error.message || 'Get export orders failed')
      }
    )
    .catch(error => {
      dispatch(
        checkHttpError({
          error,
          apiReqAction,
          genericErrorMsg: 'Get export orders failed',
          onSuccessCallback: () => {
            dispatch(getOrdersByDateRange({ fromDate, toDate }))
            dispatch({ type: types.API_END, apiReqId: apiReqAction.id })
          }
        }))
    })
}
