import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import CssBaseline from '@material-ui/core/CssBaseline'
import withWidth from '@material-ui/core/withWidth'
import { ThemeProvider } from '@material-ui/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import theme from '../lib/theme'
import { APP_ROUTES } from '../lib/constants/app-routes'
import Logout from './Logout'
import Notifications from './Notifications'
import DashboardLayoutContext from './withDashboardLayout'
/* Pages */
import LoginPage from '../app-pages/LoginPage'
import OrdersPage from '../app-pages/OrdersPage'
import ProfilePage from '../app-pages/ProfilePage'
// import ProductsPage from '../app-pages/Setup/ProductsPage'
// import CustomersPage from '../app-pages/Setup/CustomersPage'
import StandAloneOrdersTablePage from '../app-pages/OrdersTablePage' // Standalone table in page showing orders
import UserSetupPage from '../app-pages/UserSetupPage'
import FourOhFour from '../app-pages/FourOhFour'

const App = ({ width }) => {
  return (
    <SnackbarProvider preventDuplicate maxSnack={4} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DashboardLayoutContext.Provider value={width}>
            <CssBaseline />
            <Switch>
              <Redirect exact path='/' to={APP_ROUTES.orders} />
              <Redirect exact path='/home' to={APP_ROUTES.orders} />
              <Route path={APP_ROUTES.orders} exact component={OrdersPage} />
              <Route path={APP_ROUTES.orderstable} exact component={StandAloneOrdersTablePage} />
              {/* <Route path={APP_ROUTES.products} exact component={ProductsPage} />
              <Route path={APP_ROUTES.customers} exact component={CustomersPage} /> */}
              <Route path={APP_ROUTES.profile} exact component={ProfilePage} />
              <Route path={APP_ROUTES.usersetup} exact component={UserSetupPage} />
              <Route path={APP_ROUTES.login} exact component={LoginPage} />
              <Route path={APP_ROUTES.logout} exact component={Logout} />
              <Route path='*' exact component={FourOhFour} />
            </Switch>
            <Notifications />
          </DashboardLayoutContext.Provider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </SnackbarProvider>
  )
}

export default withWidth()(App)
