import * as types from './types'
import { usersApi } from '../../api'
import { apiRequest } from './api-request'
import { getAuthToken, checkHttpError } from './authTokenHelper'
import { showNotification } from './notifications'
import { toggleAddUserDialog } from './dialogs'
import { NOTIFICATION_TYPES } from '../../constants/notification-types'

export const getUsers = () => (dispatch, getState) => {
  const apiReqAction = apiRequest({ requestType: types.USERS_REQ })
  dispatch(apiReqAction)

  usersApi
    .getUsers(getAuthToken(getState()))
    .then(
      users => {
        dispatch({
          type: types.GET_USERS_OK,
          users: users.map(u => ({ ...u, isActive: !!u.isActive })),
          apiReqId: apiReqAction.id
        })
      },
      error => {
        throw new Error(error.message || 'Add user failed')
      }
    )
    .catch(error => {
      dispatch(
        checkHttpError({
          error,
          apiReqAction,
          genericErrorMsg: 'Get users failed',
          onSuccessCallback: () => {
            dispatch(getUsers())
            dispatch({ type: types.API_END, apiReqId: apiReqAction.id })
          }
        }))
    })
}

export const addUser = ({ name, email, role, password }) => (dispatch, getState) => {
  dispatch(
    showNotification({
      message: 'Saving new user to the database ...',
      options: {
        variant: NOTIFICATION_TYPES.Info
      }
    })
  )

  const apiReqAction = apiRequest({ requestType: types.USERS_ADD_REQ })
  dispatch(apiReqAction)

  usersApi
    .addUser(getAuthToken(getState()), { name, email, role, password })
    .then(
      ({ id }) => {
        dispatch(toggleAddUserDialog())
        dispatch({
          type: types.ADD_USER_OK,
          user: { name, email, role, isActive: true, id },
          apiReqId: apiReqAction.id
        })
        dispatch(
          showNotification({
            message: 'New user successfully saved',
            options: {
              variant: NOTIFICATION_TYPES.Success
            }
          })
        )
      },
      error => {
        throw new Error(error.message || 'Add user failed')
      }
    )
    .catch(error => {
      dispatch(
        checkHttpError({
          error,
          apiReqAction,
          genericErrorMsg: 'Add user failed',
          onSuccessCallback: () => {
            dispatch(addUser({ name, email, role, password }))
            dispatch({ type: types.API_END, apiReqId: apiReqAction.id })
          }
        }))
    })
}

export const deleteUser = ({ userId }) => (dispatch, getState) => {
  dispatch({
    type: types.DELETE_USER,
    userId
  })

  const apiReqAction = apiRequest({ requestType: types.USERS_DEL_REQ })
  dispatch(apiReqAction)

  usersApi
    .deleteUser(getAuthToken(getState()), { userId })
    .then(
      (isSuccess) => {
        if (!isSuccess) throw new Error('Something went wrong, please refresh your browser')
        dispatch(
          showNotification({
            message: 'User deleted from the database',
            options: {
              variant: NOTIFICATION_TYPES.Info
            }
          })
        )
        dispatch({ type: types.API_END, apiReqId: apiReqAction.id })
      },
      error => {
        throw new Error(error.message || 'Delete user failed')
      }
    )
    .catch(error => {
      dispatch(
        checkHttpError({
          error,
          apiReqAction,
          genericErrorMsg: 'Delete user failed',
          onSuccessCallback: () => {
            dispatch(deleteUser({ userId }))
            dispatch({ type: types.API_END, apiReqId: apiReqAction.id })
          }
        }))
    })
}

export const toggleUserStatus = ({ userId, isActive }) => (dispatch, getState) => {
  dispatch({
    type: types.TOGGLE_USER_STATUS,
    userId,
    isActive
  })

  const apiReqAction = apiRequest({ requestType: types.USERS_STATUS_REQ })
  dispatch(apiReqAction)

  usersApi
    .toggleUserStatus(getAuthToken(getState()), { userId, isActive })
    .then(
      (isSuccess) => {
        if (!isSuccess) throw new Error('Something went wrong, please refresh your browser')
        dispatch(
          showNotification({
            message: 'User status saved to database',
            options: {
              variant: NOTIFICATION_TYPES.Info
            }
          })
        )
        dispatch({ type: types.API_END, apiReqId: apiReqAction.id })
      },
      error => {
        throw new Error(error.message || 'Update user failed')
      }
    )
    .catch(error => {
      dispatch(
        checkHttpError({
          error,
          apiReqAction,
          genericErrorMsg: 'Update user failed',
          onSuccessCallback: () => {
            dispatch(toggleUserStatus({ userId, isActive }))
            dispatch({ type: types.API_END, apiReqId: apiReqAction.id })
          }
        }))
    })
}
