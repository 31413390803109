import { createSelector } from 'reselect'
import { API_LOGIN_REQ, API_CHANGE_PASSWORD_REQ } from '../actions/types'

const isAuthSelector = state => {
  return !!state.user.auth && !!state.user.auth.idToken
}
const authTokenSelector = state => state.user.auth.idToken

export const makeSelectIsAuth = () =>
  createSelector(
    [isAuthSelector],
    isAuth => isAuth
  )

export const makeSelectAuthToken = () =>
  createSelector(
    [authTokenSelector],
    token => token
  )

const isLoadingLoginSelector = state => {
  return !!state.apiRequest.find(req => req.requestType === API_LOGIN_REQ)
}
export const selectIsLoadingLogin = createSelector(
  [isLoadingLoginSelector],
  isLoading => isLoading
)

const isLoadingChangePasswordSelector = state => {
  return !!state.apiRequest.find(req => req.requestType === API_CHANGE_PASSWORD_REQ)
}
export const selectIsLoadingChangePassword = createSelector(
  [isLoadingChangePasswordSelector],
  isLoading => isLoading
)
