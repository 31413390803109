import * as types from './types'
import { reduxPersistor } from '../../../containers/Root'
import { authApi } from '../../api'
import { apiRequest } from './api-request'
import { showNotification } from './notifications'
import { checkHttpError } from './authTokenHelper'
import { NOTIFICATION_TYPES } from '../../constants/notification-types'

export const getIdToken = state => state ? state.user.auth.idToken : null

export const refreshIdToken = (callback) => (dispatch, getState) => {
  const { refreshToken } = getState().user.auth

  if (!refreshToken) return

  authApi
    .refreshIdToken({ refreshToken })
    .then(
      idToken => {
        if (!idToken) return dispatch(logout(reduxPersistor))
        dispatch({
          type: types.REFRESH_TOKEN_OK,
          idToken
        })
        if (callback && typeof callback === 'function') callback()
      }
    )
    .catch(() => {
      dispatch(logout(reduxPersistor))
    })
}

export const login = (email, password) => dispatch => {
  const apiReqAction = apiRequest({ requestType: types.API_LOGIN_REQ })
  dispatch(apiReqAction)

  authApi
    .login({ email, password })
    .then(
      loginData => {
        const { idToken, refreshToken, displayName, role } = loginData
        dispatch({
          type: types.LOGIN_OK,
          auth: { idToken, refreshToken },
          user: { displayName, email, role },
          apiReqId: apiReqAction.id
        })
      },
      error => {
        dispatch(
          showNotification({
            message: `${error.message}` || 'Login failed',
            options: {
              variant: NOTIFICATION_TYPES.Error
            }
          })
        )
        dispatch({ type: types.API_END, apiReqId: apiReqAction.id })
      }
    )
    .catch(error => {
      dispatch(
        showNotification({
          message: `${error.message}` || 'Login failed',
          options: {
            variant: NOTIFICATION_TYPES.Error
          }
        })
      )
      dispatch({ type: types.API_END, apiReqId: apiReqAction.id })
    })
}

export const logout = (persistor) => (dispatch) => {
  dispatch({ type: types.LOGOUT })
  if (persistor && persistor.purge && typeof persistor.purge === 'function') persistor.purge()
}

export const changePassword = ({ currentPassword, newPassword }) => (dispatch, getState) => {
  dispatch(
    showNotification({
      message: 'Changing password ...',
      options: {
        variant: NOTIFICATION_TYPES.Info
      }
    })
  )

  const apiReqAction = apiRequest({ requestType: types.API_CHANGE_PASSWORD_REQ })
  dispatch(apiReqAction)

  authApi
    .changePassword(getIdToken(getState()), { currentPassword, newPassword })
    .then(
      (success) => {
        if (!success) throw new Error('Change password failed')
        dispatch({
          type: types.CHANGE_PASSWORD_OK,
          apiReqId: apiReqAction.id
        })
        dispatch(
          showNotification({
            message: 'New password successfully saved',
            options: {
              variant: NOTIFICATION_TYPES.Success
            }
          })
        )
      },
      error => {
        throw new Error(error.message || 'Password update failed')
      }
    )
    .catch(error => {
      dispatch(
        checkHttpError({
          error,
          apiReqAction,
          genericErrorMsg: 'Password update failed',
          onSuccessCallback: () => {
            dispatch(changePassword({ currentPassword, newPassword }))
            dispatch({ type: types.API_END, apiReqId: apiReqAction.id })
          }
        }))
    })
}
