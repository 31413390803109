import { loginRequest, authRequest, nonAuthRequest } from './apiSetup'
import { API_ENDPOINTS } from '../../constants/api-endpoints'

/*
 * Authentication API
 ** */

// const delay = (ms) => new Promise(resolve => setTimeout(() => resolve(true), ms))

export function login ({ email, password }) {
  return loginRequest({ email, password })
}

export function changePassword (idToken, { currentPassword, newPassword }) {
  const endpoint = API_ENDPOINTS.changePassword
  return authRequest(idToken, { method: 'PUT', endpoint, body: { currentPassword, newPassword } })
    .then(response => {
      const { updated } = response
      return updated
    })
    .catch(error => {
      // HTTP conflict, current password is not correct
      if (error.response && error.response.status === 409) {
        throw new Error('Incorrect current password')
      } else {
        throw new Error(error.message || 'There was a problem processing the request')
      }
    })
}

export function refreshIdToken ({ refreshToken }) {
  const endpoint = API_ENDPOINTS.refreshIdToken

  return nonAuthRequest({
    method: 'POST',
    endpoint,
    body: { refreshToken }
  })
    .then(response => {
      const { idToken } = response
      return idToken
    })
    .catch(error => {
      throw new Error(error.message || '')
    })
}
