import { createReducer } from 'redux-create-reducer'
import * as types from '../actions/types'

const initialState = {
  list: [],
  ordersFound: null
}

export const exportOrders = createReducer(initialState, {
  [types.GET_CSV_EXPORT_OK] (state, action) {
    const { orders } = action
    return {
      ...state,
      list: [...orders],
      ordersFound: !!orders?.length
    }
  },
  [types.RESET_CSV_EXPORT] () {
    return initialState
  },
  [types.LOGOUT] () {
    return initialState
  }
})
