/* eslint-disable react/display-name */
import React from 'react'
import DashboardLayout from '../components/DashboardLayout'
import { version } from '../../package.json'

const DashboardLayoutContext = React.createContext(null)

export const withDashboardLayout = Component => props => (
  <DashboardLayoutContext.Consumer>
    {width => {
      function Layout () {
        const [isDrawerOpen, setDrawerOpen] = React.useState(!(width === 'xs' || width === 'sm'))
        const handleDrawerOpen = () => {
          setDrawerOpen(true)
        }
        const handleDrawerClose = () => {
          setDrawerOpen(false)
        }

        return (
          <DashboardLayout
            isDrawerOpen={isDrawerOpen}
            onDrawerOpen={handleDrawerOpen}
            onDrawerClose={handleDrawerClose}
            version={version}
          >
            <Component {...props} />
          </DashboardLayout>
        )
      }
      return <Layout />
    }}
  </DashboardLayoutContext.Consumer>
)

export default DashboardLayoutContext
