import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { DRAWER_WIDTH } from './DashboardLayout'
import bgImage from '../_assets/images/drawer-bg.jpg'
import logoImage from '../_assets/images/logo.png'

const useStyles = makeStyles(theme => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  drawerPaper: {
    background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  iconColor: {
    color: 'white'
  },
  version: {
    color: '#c7c7c7',
    marginBottom: theme.spacing(2)
  },
  divider: {
    backgroundColor: 'rgba(239, 239, 239, 0.3)'
  },
  versionBox: {
    flexGrow: 1
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '64px',
    maxHeight: '64px'
  },
  bgWhite: {
    backgroundColor: 'white'
  },
  bgGrey: {
    backgroundColor: '##3a3a3a'
  }
}))

const AppDrawer = ({ isDrawerOpen, menu, appVersion }) => {
  const classes = useStyles()

  return (
    <Drawer
      variant='permanent'
      classes={{
        paper: clsx(classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose)
      }}
      open={isDrawerOpen}
    >
      <div className={clsx(classes.logoContainer, classes.bgGrey)}>
        <img src={logoImage} alt='Purewater' height='55' />
      </div>
      <Divider className={classes.divider} />
      {menu}
      <Box
        className={classes.versionBox}
        display='flex'
        flexDirection='column'
        justifyContent='flex-end'
        alignItems='center'
      >
        <Typography component='span' variant='body2' className={classes.version}>
          &copy; Orders Dashboard - v{appVersion}
        </Typography>
      </Box>
    </Drawer>
  )
}

AppDrawer.propTypes = {
  isDrawerOpen: PropTypes.bool,
  menu: PropTypes.node,
  appVersion: PropTypes.string
}

export default AppDrawer
