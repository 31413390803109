import * as auth from './handlers/authentication'
import * as customers from './handlers/customers'
import * as orders from './handlers/orders'
import * as products from './handlers/products'
import * as users from './handlers/users'

export const authApi = auth
export const customersApi = customers
export const ordersApi = orders
export const productsApi = products
export const usersApi = users
