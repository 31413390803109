import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import OrderDialogComponent from '../components/OrderDialog'
import { toggleOrderDialog } from '../lib/store/actions/dialogs'
import { addOrder, updateOrder, resetModifyOrderStatus, getLastDeliveryTimes } from '../lib/store/actions/orders'
import { makeSelectIsOrderDialogOpen } from '../lib/store/selectors/dialogs'
import { makeSelectedOrder, makeSelectOrderAddedStatus } from '../lib/store/selectors/orders'
import { makeSelectIsDoingAddOrderAPIRequest, makeSelectIsDoingUpdateOrderAPIRequest } from '../lib/store/selectors/api-request'

function OrderDialog ({ isOpen, isAddingOrder, isUpdatingOrder, orderModifiedStatus, onToggleDialog, onAddOrder, onUpdateOrder, onResetAddOrderStatus, selectedOrder, onGetLastDeliveryTimes }) {
  React.useEffect(() => {
    if (orderModifiedStatus.status === true) {
      onResetAddOrderStatus()
      onToggleDialog()
    }
  }, [orderModifiedStatus, onResetAddOrderStatus, onToggleDialog])

  const handleSubmit = (order) => {
    if (selectedOrder) {
      onUpdateOrder({ orderId: selectedOrder.id, order }, () => { onGetLastDeliveryTimes() })
    } else {
      onAddOrder({ order }, () => { onGetLastDeliveryTimes() })
    }
  }

  return <OrderDialogComponent orderModifiedStatus={orderModifiedStatus} isLoading={isAddingOrder || isUpdatingOrder} isOpen={isOpen} onClose={onToggleDialog} onSubmit={handleSubmit} selectedOrder={selectedOrder} />
}

OrderDialog.propTypes = {
  isOpen: PropTypes.bool,
  onToggleDialog: PropTypes.func,
  onAddUser: PropTypes.func
}

const mapStateToProps = state => {
  const selectIsOrderDialogOpen = makeSelectIsOrderDialogOpen()
  const selectedOrder = makeSelectedOrder()
  const selectIsDoingAddOrderAPIRequest = makeSelectIsDoingAddOrderAPIRequest()
  const selectIsDoingUpdateOrderAPIRequest = makeSelectIsDoingUpdateOrderAPIRequest()
  const selectOrderAddedStatus = makeSelectOrderAddedStatus()
  return {
    isOpen: selectIsOrderDialogOpen(state),
    isAddingOrder: selectIsDoingAddOrderAPIRequest(state),
    isUpdatingOrder: selectIsDoingUpdateOrderAPIRequest(state),
    selectedOrder: selectedOrder(state),
    orderModifiedStatus: selectOrderAddedStatus(state)
  }
}

export default connect(
  mapStateToProps,
  {
    onToggleDialog: toggleOrderDialog,
    onAddOrder: addOrder,
    onUpdateOrder: updateOrder,
    onResetAddOrderStatus: resetModifyOrderStatus,
    onGetLastDeliveryTimes: getLastDeliveryTimes
  }
)(OrderDialog)
