import React from 'react'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import teal from '@material-ui/core/colors/teal'
import lightBlue from '@material-ui/core/colors/lightBlue'
import deepOrange from '@material-ui/core/colors/deepOrange'
import { makeStyles } from '@material-ui/core/styles'

const useCardStyles = makeStyles(() => ({
  card: {
    borderRadius: 4,
    paddingTop: 0,
    paddingLeft: 10,
    paddingRight: 10
  },
  title: {
    fontSize: '1em',
    display: 'inline-block',
    marginRight: 5,
    textTransform: 'uppercase'
  },
  amount: {
    fontSize: '1.5em',
    marginRight: 5,
    textTransform: 'uppercase'
  }
}))

const useStyles = makeStyles(() => ({
  card: {
    border: '1px solid',
    minWidth: 150,
    textAlign: 'center'
  },
  orderCard: {
    color: deepOrange[700],
    borderColor: deepOrange[50],
    backgroundColor: deepOrange[50]
  },
  deliveryCard: {
    color: teal[700],
    borderColor: teal[50],
    backgroundColor: teal[50]
  },
  quantityCard: {
    color: lightBlue[700],
    borderColor: lightBlue[50],
    backgroundColor: lightBlue[50]
  }
}))

const Card = ({ title, amount, classNames }) => {
  const classes = useCardStyles()
  return (
    <Box className={clsx(classes.card, classNames)}>
      <Typography component='span' variant='body2' className={classes.title}>
        {title}
      </Typography>
      <Typography component='span' variant='body1' className={classes.amount}>
        {amount}
      </Typography>
    </Box>
  )
}

function OrderStats ({ orders, delivered, quantity }) {
  const classes = useStyles()

  return (
    <div>
      <Box display='flex' justifyContent='center' alignItems='center' mb={1}>
        <Card title='Orders' amount={orders} classNames={clsx(classes.card, classes.orderCard)} />
        <Box mx={1} />
        <Card title='Delivered' amount={delivered} classNames={clsx(classes.card, classes.deliveryCard)} />
        <Box mx={1} />
        <Card title='19L' amount={quantity} classNames={clsx(classes.card, classes.quantityCard)} />
      </Box>
    </div>
  )
}

export default OrderStats
