/* eslint-disable react/display-name */
import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import isPropsEqual from 'react-fast-compare'
import TableCellComponent from '@material-ui/core/TableCell'
import TableRowComponent from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'
import lightBlue from '@material-ui/core/colors/lightBlue'
import grey from '@material-ui/core/colors/grey'
import { ORDER_STATUS, ORDER_STATUS_COLORS } from '../lib/constants/order-status'

const useStyles = makeStyles(() => ({
  capitalize: {
    textTransform: 'capitalize'
  },
  bold: {
    fontWeight: 'bold'
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: lightBlue[50]
    }
  },
  noWrap: {
    whiteSpace: 'nowrap'
  },
  [`${ORDER_STATUS.red}-bg`]: {
    backgroundColor: ORDER_STATUS_COLORS[ORDER_STATUS.red].backgroundColor
  },
  [`${ORDER_STATUS.green}-bg`]: {
    backgroundColor: ORDER_STATUS_COLORS[ORDER_STATUS.green].backgroundColor
  },
  [`${ORDER_STATUS.black}-bg`]: {
    backgroundColor: ORDER_STATUS_COLORS[ORDER_STATUS.black].backgroundColor
  },
  [`${ORDER_STATUS.red}-text`]: {
    color: ORDER_STATUS_COLORS[ORDER_STATUS.red].color
  },
  [`${ORDER_STATUS.green}-text`]: {
    color: ORDER_STATUS_COLORS[ORDER_STATUS.green].color
  },
  [`${ORDER_STATUS.black}-text`]: {
    color: ORDER_STATUS_COLORS[ORDER_STATUS.black].color
  },
  cellBorder: {
    borderBottom: '1px solid',
    borderBottomColor: grey[400]
  }
}))

const TableCell = React.memo((props) =>
  <TableCellComponent {...props} />
, isPropsEqual)

const TableRow = React.memo((props) =>
  <TableRowComponent {...props} />
, isPropsEqual)

function OrdersTableRow ({
  isAccountValid,
  isCash,
  quantity,
  deliveryInstructions,
  deliveryDateTime,
  deliveryDocket,
  deliveryTeam,
  name,
  phone,
  isPriority,
  purchaseOrder,
  orderDateTime,
  rowStatusColor,
  onClick,
  disabled
}) {
  const classes = useStyles()

  return (
    <TableRow onClick={disabled ? null : onClick} className={clsx(!disabled && classes.tableRow, classes[`${rowStatusColor}-bg`])}>
      <TableCell className={classes.cellBorder}>
        {isPriority && <CheckIcon className={classes[`${rowStatusColor}-text`]} />}
      </TableCell>
      <TableCell className={classes.cellBorder}>
        <Typography component='div' variant='body2' className={clsx(classes[`${rowStatusColor}-text`])}>
          {quantity || '-'}
        </Typography>
      </TableCell>
      <TableCell className={classes.cellBorder}>
        <Typography component='div' variant='body2' className={clsx(classes.bold, classes[`${rowStatusColor}-text`])}>
          {name}{phone ? ` (${phone})` : ''}
        </Typography>
        {deliveryInstructions && (
          <div style={{ maxWidth: 300 }}>
            <Typography component='span' variant='body2' className={clsx(classes[`${rowStatusColor}-text`])}>
              {deliveryInstructions}
            </Typography>
          </div>

        )}
      </TableCell>
      <TableCell className={classes.cellBorder}>
        <Typography component='span' variant='body2' className={clsx(classes.noWrap, classes[`${rowStatusColor}-text`])}>
          {purchaseOrder}
        </Typography>
      </TableCell>
      <TableCell className={classes.cellBorder}>
        <Typography component='span' variant='body2' className={classes[`${rowStatusColor}-text`]}>
          {orderDateTime}
        </Typography>
      </TableCell>
      <TableCell className={classes.cellBorder}>
        {isAccountValid && <CheckIcon className={classes[`${rowStatusColor}-text`]} />}
      </TableCell>
      <TableCell className={classes.cellBorder}>
        {isCash && <CheckIcon className={classes[`${rowStatusColor}-text`]} />}
      </TableCell>
      <TableCell className={classes.cellBorder}>
        <Typography component='span' variant='body2' className={clsx(classes.noWrap, classes[`${rowStatusColor}-text`])}>
          {deliveryTeam}
        </Typography>
      </TableCell>
      <TableCell className={classes.cellBorder}>
        <Typography component='span' variant='body2' className={classes[`${rowStatusColor}-text`]}>
          {deliveryDateTime}
        </Typography>
      </TableCell>
      <TableCell className={classes.cellBorder}>
        <Typography component='span' variant='body2' className={clsx(classes.noWrap, classes[`${rowStatusColor}-text`])}>
          {deliveryDocket}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

OrdersTableRow.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  isPriority: PropTypes.bool
}

export default React.memo(OrdersTableRow, isPropsEqual)
