import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import UserTable from '../components/UserTable'
import { getUsers, deleteUser, toggleUserStatus } from '../lib/store/actions/users'
import { makeSelectUsers } from '../lib/store/selectors/users'
import { makeSelectUser } from '../lib/store/selectors/user'
import { makeSelectIsDoingGetUsersAPIRequest } from '../lib/store/selectors/api-request'

function UserSetup ({
  users,
  onGetUsers,
  onDeleteUser,
  onToggleUserStatus,
  isLoading,
  me
}) {
  React.useEffect(() => {
    onGetUsers()
  }, [onGetUsers])

  const handleDelete = (userId) => {
    onDeleteUser({ userId })
  }

  const handleToggleStatus = (userId, isActive) => {
    onToggleUserStatus({ userId, isActive })
  }

  return <UserTable me={me} isLoading={isLoading} users={users} onDelete={handleDelete} oToggleStatus={handleToggleStatus} />
}

UserSetup.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    isActive: PropTypes.bool
  })),
  onGetUsers: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onToggleUserStatus: PropTypes.func,
  isLoading: PropTypes.bool,
  me: PropTypes.shape()
}

const mapStateToProps = state => {
  const selectUsers = makeSelectUsers()
  const selectIsDoingGetUsersAPIRequest = makeSelectIsDoingGetUsersAPIRequest()
  const selectMe = makeSelectUser()

  return {
    users: selectUsers(state),
    isLoading: selectIsDoingGetUsersAPIRequest(state),
    me: selectMe(state)
  }
}

export default connect(
  mapStateToProps,
  {
    onGetUsers: getUsers,
    onDeleteUser: deleteUser,
    onToggleUserStatus: toggleUserStatus
  }
)(UserSetup)
