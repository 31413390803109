import React from 'react'
import PropTypes from 'prop-types'
import isEqual from 'react-fast-compare'
import { connect } from 'react-redux'
import { getOrders, getLastDeliveryTimes } from '../../lib/store/actions/orders'

function InitOrders ({ onGetOrders, onGetLastDeliveryTimes }) {
  React.useEffect(() => {
    // TODO: check if the path is not orderstable, then init orders
    onGetOrders()
    onGetLastDeliveryTimes()
  }, [onGetOrders, onGetLastDeliveryTimes])

  return null
}

InitOrders.propTypes = {
  onGetOrders: PropTypes.func
}

export default connect(
  null,
  {
    onGetOrders: getOrders,
    onGetLastDeliveryTimes: getLastDeliveryTimes
  }
)(React.memo(InitOrders, isEqual))
