/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import isPropsEqual from 'react-fast-compare'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import DatePicker from '../components/forms/DatePicker'
import LoadingIndicator from '../components/LoadingIndicator'
import Modal from './Modal'
import 'moment/min/locales'

moment.locale('en-AU')

function OrderCSVExportDialog ({
  isOpen,
  isLoading,
  hasFoundOrders,
  onClose,
  onSubmit
}) {
  const initialValues = {
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD')
  }

  const [values, setValues] = React.useState(initialValues)

  const handleDateChange = field => date => {
    setValues(vals => ({
      ...vals,
      [field]: date ? moment(date).format('YYYY-MM-DD') : null
    }))
  }

  const handleClose = () => {
    setValues(initialValues)
    onClose()
  }

  const handleSubmit = () => {
    setValues(vals => ({ ...vals, isSubmitted: true }))

    const { fromDate, toDate } = values

    onSubmit({
      fromDate,
      toDate
    })
  }

  const buttons = [
    {
      id: '1',
      onClick: handleClose,
      color: 'primary',
      children: 'Close',
      variant: 'outlined',
      disabled: isLoading,
      style: { marginRight: 10 }
    },
    {
      id: '2',
      onClick: handleSubmit,
      color: 'primary',
      children: 'Export',
      variant: 'contained',
      disabled: isLoading
    }
  ]

  const defaultTextFieldProps = {
    fullWidth: true,
    variant: 'outlined'
  }

  const fieldsLeft = [
    {
      ...defaultTextFieldProps,
      id: 'fromDate',
      label: 'From Date',
      value: values.fromDate,
      onChange: handleDateChange('fromDate'),
      format: 'DD/MM/YYYY',
      variant: 'dialog',
      disabled: isLoading,
      clearable: false,
      showTodayButton: true,
      inputVariant: 'outlined',
      grid: {
        xs: 12,
        lg: 6
      }
    },
    {
      ...defaultTextFieldProps,
      id: 'toDate',
      label: 'To Date',
      value: values.toDate,
      onChange: handleDateChange('toDate'),
      format: 'DD/MM/YYYY',
      variant: 'dialog',
      clearable: false,
      disabled: isLoading,
      showTodayButton: true,
      inputVariant: 'outlined',
      grid: {
        xs: 12,
        lg: 6
      }
    }
  ]

  const fieldMapper = ({ id, grid, ...props }) => {
    const gridProps = grid ? { ...grid } : { xs: 12 }
    return (
      <Grid key={id} item {...gridProps}>
        <DatePicker id={id} {...props} />
      </Grid>
    )
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      dialogTitleStyle={{ backgroundColor: '#0d47a1', color: '#efefef' }}
      title='Orders CSV Export'
      buttons={buttons}
      dialogProps={{ fullWidth: true, maxWidth: 'sm', disableBackdropClick: true, disableEscapeKeyDown: true }}
      progress={
        <>
          {isLoading && <span><em>Exporting data, please wait</em> ... <LoadingIndicator size={12} /></span>}
          {hasFoundOrders === false && <Box color='error.main'><strong>No orders found. Please adjust the dates.</strong></Box>}
        </>
      }
    >
      <Box mt={2} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component='span' variant='body1'>Export orders received or processed between the following dates</Typography>
        </Grid>
        {fieldsLeft.map(fieldMapper)}
      </Grid>
    </Modal>
  )
}

OrderCSVExportDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
}

export default React.memo(OrderCSVExportDialog, isPropsEqual)
